// src/pages/Settings/tabs/SupportTab.js
import React, { useState } from "react";

const SupportTab = () => {
    const [message, setMessage] = useState("");

    const handleSendMessage = () => {
        console.log("User message:", message);
        alert("Message sent! (Future: backend support integration)");
    };

    return (
        <div>
            <h3 className="mb-4 text-2xl font-bold">Support</h3>

            <p className="mb-4">
                If you need help, email us at{" "}
                <a href="mailto:support@pathtochrist.life" className="text-primary-500">
                    support@pathtochrist.life
                </a>
                .
            </p>

            <p className="mb-4">
                For bugs or feature requests, visit our{" "}
                <a href="/canny" className="text-primary-500">Canny board</a>.
            </p>

            <p className="mb-4">
                Follow us on{" "}
                <a href="https://instagram.com/pathtochrist.life" className="text-primary-500">Instagram</a>{" "}
                and{" "}
                <a href="https://twitter.com/pathtochristlife" className="text-primary-500">Twitter</a>.
            </p>

            {/* Send Message */}
            <div className="mb-6">
                <label className="block mb-2 text-sm font-bold text-gray-700">Send a Message</label>
                <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={6}
                    className="w-full px-4 py-2 border bg-meek-light rounded-3xl"
                    placeholder="Send us a message"
                />
                <div className="flex justify-end mt-2">
                    <button
                        onClick={handleSendMessage}
                        className="px-6 py-2 font-bold text-white bg-primary-500 rounded-3xl hover:bg-primary-700"
                    >
                        Send
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SupportTab;
