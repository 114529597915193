import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../constants/Navbar";

// Utility function to combine Tailwind classes conditionally
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Hard-coded volumes data
const volumesData = [
  { volume_id: 1, volume_lds_url: "ot", displayName: "Old Testament" },
  { volume_id: 2, volume_lds_url: "nt", displayName: "New Testament" },
  { volume_id: 3, volume_lds_url: "bofm", displayName: "Book of Mormon" },
  { volume_id: 4, volume_lds_url: "dc-testament", displayName: "Doctrine & Covenants" },
  { volume_id: 5, volume_lds_url: "pgp", displayName: "Pearl of Great Price" },
];

const ScriptureViewer = () => {
  const navigate = useNavigate();
  const { volume, book, chapter } = useParams();

  // State for books, chapters, and verses
  const [booksData, setBooksData] = useState([]);
  const [chaptersData, setChaptersData] = useState([]);
  const [versesData, setVersesData] = useState([]);

  // Error states
  const [booksError, setBooksError] = useState(null);
  const [chaptersError, setChaptersError] = useState(null);
  const [versesError, setVersesError] = useState(null);

  // Loading states
  const [loadingBooks, setLoadingBooks] = useState(false);
  const [loadingChapters, setLoadingChapters] = useState(false);
  const [loadingVerses, setLoadingVerses] = useState(false);

  // Local state for navigation selections (mirroring URL)
  const [selectedVolume, setSelectedVolume] = useState(volume || "");
  const [selectedBook, setSelectedBook] = useState(book || "");
  const [selectedChapter, setSelectedChapter] = useState(chapter || "");
  console.log("Selected:", selectedVolume, selectedBook, selectedChapter);

  // Sidebar open/close for mobile
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Accordion toggles
  const [volumesOpen, setVolumesOpen] = useState(true);
  const [booksOpen, setBooksOpen] = useState(false);
  const [chaptersOpen, setChaptersOpen] = useState(false);

  // Find current volume object based on URL param
  const currentVolumeObj = volumesData.find(
    (volObj) => volObj.volume_lds_url === volume
  );

  // -------------------------------
  // Data Fetching Functions
  // -------------------------------

  const fetchBooks = async (volumeId) => {
    setLoadingBooks(true);
    setBooksError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/scriptures/volume/${volumeId}`
      );
      setBooksData(response.data);
    } catch (error) {
      console.error("Error fetching books:", error);
      setBooksError("Failed to load books. Please try again later.");
    }
    setLoadingBooks(false);
  };

  const fetchChapters = async (bookId) => {
    setLoadingChapters(true);
    setChaptersError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/scriptures/book/${bookId}/chapters`
      );
      setChaptersData(response.data);
    } catch (error) {
      console.error("Error fetching chapters:", error);
      setChaptersError("Failed to load chapters. Please try again later.");
    }
    setLoadingChapters(false);
  };

  const fetchVerses = async (chapterId) => {
    setLoadingVerses(true);
    setVersesError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/scriptures/chapter/${chapterId}`
      );
      setVersesData(response.data);
    } catch (error) {
      console.error("Error fetching verses:", error);
      setVersesError("Failed to load verses. Please try again later.");
    }
    setLoadingVerses(false);
  };

  // Fetch books when volume changes
  useEffect(() => {
    if (currentVolumeObj) {
      fetchBooks(currentVolumeObj.volume_id);
    } else {
      setBooksData([]);
    }
  }, [volume]);

  // Fetch chapters when book changes
  useEffect(() => {
    if (book) {
      fetchChapters(book);
    } else {
      setChaptersData([]);
    }
  }, [book]);

  // Fetch verses when chapter changes
  useEffect(() => {
    if (chapter) {
      fetchVerses(chapter);
    } else {
      setVersesData([]);
    }
  }, [chapter]);

  // -------------------------------
  // Accordion Auto-Toggle
  // -------------------------------
  useEffect(() => {
    if (!volume) {
      setVolumesOpen(true);
      setBooksOpen(false);
      setChaptersOpen(false);
    } else if (volume && !book) {
      setVolumesOpen(false);
      setBooksOpen(true);
      setChaptersOpen(false);
    } else if (volume && book && !chapter) {
      setVolumesOpen(false);
      setBooksOpen(false);
      setChaptersOpen(true);
    } else if (volume && book && chapter) {
      setVolumesOpen(false);
      setBooksOpen(false);
      setChaptersOpen(true);
    }
  }, [volume, book, chapter]);

  // -------------------------------
  // Navigation Handlers
  // -------------------------------
  const handleVolumeSelect = (volumeSlug) => {
    setSelectedVolume(volumeSlug);
    setSelectedBook("");
    setSelectedChapter("");
    navigate(`/scriptures/${volumeSlug}`);
    setSidebarOpen(false);
  };

  const handleBookSelect = (bookId) => {
    setSelectedBook(bookId);
    setSelectedChapter("");
    if (volume && bookId) {
      navigate(`/scriptures/${volume}/${bookId}`);
    }
    setSidebarOpen(false);
  };

  const handleChapterSelect = (chapterId) => {
    setSelectedChapter(chapterId);
    if (volume && book && chapterId) {
      navigate(`/scriptures/${volume}/${book}/${chapterId}`);
    }
    setSidebarOpen(false);
  };

  // -------------------------------
  // Derived Data
  // -------------------------------
  const selectedBookObj = booksData.find((b) => b.book_id.toString() === book);
  const selectedChapterObj = chaptersData.find(
    (c) => c.chapter_id.toString() === chapter
  );

  const sideNavVolumes = volumesData.map((v) => ({
    name: v.displayName,
    slug: v.volume_lds_url,
    current: volume === v.volume_lds_url,
  }));

  const sideNavBooks =
    volume && booksData.length > 0
      ? booksData.map((b) => ({
        name: b.book_title,
        bookId: b.book_id,
        current: b.book_id.toString() === book,
      }))
      : [];

  const sideNavChapters =
    book && chaptersData.length > 0
      ? chaptersData.map((c) => ({
        name: `Chapter ${c.chapter_number}`,
        chapterId: c.chapter_id,
        current: c.chapter_id.toString() === chapter,
      }))
      : [];

  // -------------------------------
  // Sidebar Content
  // -------------------------------
  const sidebarContent = (
    <div className="flex flex-col h-full border-r border-gray-200 bg-gray-50">
      <div className="p-4 text-lg font-bold text-gray-800 border-b border-gray-200">
        Scripture Nav
      </div>
      <nav aria-label="Sidebar" className="flex-1 p-4 overflow-y-auto">
        {/* Volumes */}
        <div>
          <button
            onClick={() => setVolumesOpen((prev) => !prev)}
            className="flex items-center justify-between w-full mb-2 text-sm font-semibold tracking-wide text-left text-gray-500 uppercase hover:text-indigo-600"
          >
            Volumes
            <span>{volumesOpen ? "−" : "+"}</span>
          </button>
          {volumesOpen && (
            <ul role="list" className="mb-4 space-y-1">
              {sideNavVolumes.map((item) => (
                <li key={item.slug}>
                  <button
                    onClick={() => handleVolumeSelect(item.slug)}
                    className={classNames(
                      item.current
                        ? "bg-gray-100 text-indigo-600"
                        : "text-gray-700 hover:bg-gray-100 hover:text-indigo-600",
                      "group w-full text-left flex items-center gap-x-2 rounded-md p-2 text-sm font-semibold"
                    )}
                  >
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Books */}
        {volume && (
          <div>
            <button
              onClick={() => setBooksOpen((prev) => !prev)}
              className="flex items-center justify-between w-full mb-2 text-sm font-semibold tracking-wide text-left text-gray-500 uppercase hover:text-indigo-600"
            >
              Books
              <span>{booksOpen ? "−" : "+"}</span>
            </button>
            {booksOpen &&
              (loadingBooks ? (
                <p className="text-sm text-gray-500">Loading books...</p>
              ) : booksError ? (
                <p className="text-sm text-red-600">{booksError}</p>
              ) : (
                <ul role="list" className="mb-4 space-y-1">
                  {sideNavBooks.map((item) => (
                    <li key={item.bookId}>
                      <button
                        onClick={() => handleBookSelect(item.bookId)}
                        className={classNames(
                          item.current
                            ? "bg-gray-100 text-indigo-600"
                            : "text-gray-700 hover:bg-gray-100 hover:text-indigo-600",
                          "group w-full text-left flex items-center gap-x-2 rounded-md p-2 text-sm font-semibold"
                        )}
                      >
                        {item.name}
                      </button>
                    </li>
                  ))}
                </ul>
              ))}
          </div>
        )}

        {/* Chapters */}
        {book && (
          <div>
            <button
              onClick={() => setChaptersOpen((prev) => !prev)}
              className="flex items-center justify-between w-full mb-2 text-sm font-semibold tracking-wide text-left text-gray-500 uppercase hover:text-indigo-600"
            >
              Chapters
              <span>{chaptersOpen ? "−" : "+"}</span>
            </button>
            {chaptersOpen &&
              (loadingChapters ? (
                <p className="text-sm text-gray-500">Loading chapters...</p>
              ) : chaptersError ? (
                <p className="text-sm text-red-600">{chaptersError}</p>
              ) : (
                <ul role="list" className="space-y-1">
                  {sideNavChapters.map((item) => (
                    <li key={item.chapterId}>
                      <button
                        onClick={() => handleChapterSelect(item.chapterId)}
                        className={classNames(
                          item.current
                            ? "bg-gray-100 text-indigo-600"
                            : "text-gray-700 hover:bg-gray-100 hover:text-indigo-600",
                          "group w-full text-left flex items-center gap-x-2 rounded-md p-2 text-sm font-semibold"
                        )}
                      >
                        {item.name}
                      </button>
                    </li>
                  ))}
                </ul>
              ))}
          </div>
        )}
      </nav>
    </div>
  );

  // -------------------------------
  // Layout & Main Content
  // -------------------------------
  return (
    <>
      <Navbar />
      <div className="flex flex-col h-screen max-w-[988px] mx-auto">

        {/* Hamburger button for mobile */}
        <div className="absolute top-4 left-4 md:hidden">
          <button
            type="button"
            onClick={() => setSidebarOpen(true)}
            className="inline-flex items-center justify-center p-2 text-gray-500 bg-white rounded-md shadow hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          >
            <span className="sr-only">Open sidebar</span>
            <svg className="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>

        {/* Mobile Sidebar Overlay */}
        {sidebarOpen && (
          <div className="fixed inset-0 z-40 flex md:hidden">
            {/* Overlay */}
            <div
              className="fixed inset-0 bg-gray-600 bg-opacity-75"
              onClick={() => setSidebarOpen(false)}
              aria-hidden="true"
            />
            {/* Sidebar */}
            <div className="relative flex flex-col w-64 h-full bg-white">
              <div className="absolute top-0 right-0 p-2">
                <button
                  type="button"
                  onClick={() => setSidebarOpen(false)}
                  className="inline-flex items-center justify-center p-2 text-gray-500 bg-white rounded-md shadow hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span className="sr-only">Close sidebar</span>
                  <svg className="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              {sidebarContent}
            </div>
          </div>
        )}

        {/* Flex Container: Sidebar (Desktop) + Main Content */}
        <div className="flex flex-1 overflow-hidden">
          {/* Desktop Sidebar */}
          <div className="hidden md:flex md:w-64 md:flex-col md:shrink-0">
            {sidebarContent}
          </div>

          {/* Main Content */}
          <div className="flex-1 p-6 overflow-y-auto">
            {/* No volume selected */}
            {!volume && (
              <div className="text-gray-600">
                <h1 className="mb-2 text-xl font-bold">Choose a volume</h1>
                <p>Select a volume from the sidebar to begin reading.</p>
              </div>
            )}

            {/* Volume selected, but no book */}
            {volume && !book && (
              <div>
                <h1 className="mb-2 text-2xl font-bold text-gray-800">
                  {currentVolumeObj?.displayName}
                </h1>
                <p className="text-gray-600">Please choose a book from the sidebar.</p>
              </div>
            )}

            {/* Book selected, but no chapter */}
            {volume && book && !chapter && selectedBookObj && (
              <div>
                <h1 className="mb-2 text-2xl font-bold text-gray-800">
                  {currentVolumeObj?.displayName}
                </h1>
                <h2 className="mb-4 text-xl font-semibold text-gray-700">
                  {selectedBookObj.book_title}
                </h2>
                <p className="text-gray-600">Please choose a chapter from the sidebar.</p>
              </div>
            )}

            {/* Volume, book, and chapter selected */}
            {volume && book && chapter && selectedChapterObj && (
              <div className="mx-auto max-w-prose">
                <h1 className="mb-2 text-2xl font-bold text-gray-800">
                  {currentVolumeObj?.displayName}
                </h1>
                <h2 className="mb-4 text-xl font-semibold text-gray-700">
                  {selectedBookObj?.book_title} – Chapter {selectedChapterObj?.chapter_number}
                </h2>
                {loadingVerses ? (
                  <p className="text-gray-500">Loading verses...</p>
                ) : versesError ? (
                  <p className="text-red-600">{versesError}</p>
                ) : versesData.length > 0 ? (
                  <div className="flex flex-col gap-3">
                    {versesData.map((verse) => (
                      <div key={verse._id}>
                        <span className="mr-2 font-semibold text-gray-800">
                          {verse.verse_number}
                        </span>
                        <span className="text-gray-700">{verse.scripture_text}</span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500">No verses available for this chapter.</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ScriptureViewer;
