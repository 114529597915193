import React from "react";
import { FaDiscord } from "react-icons/fa";

// Navigation data arrays
const features = [
  { label: "Self-Assessment", href: "/self-assessment" },
  { label: "What Lack I Yet?", href: "/articles", comingSoon: true },
  { label: "My Study Plan", href: "/resources", comingSoon: true },
  { label: "Global Discipleship", href: "/resources", comingSoon: true },
];

const connectLinks = [
  { label: "Discord Server", href: process.env.REACT_APP_DISCORD_INVITE },
  { label: "Subreddit", href: process.env.REACT_APP_REDDIT },
  { label: "Roadmap & Feature Requests", href: process.env.REACT_APP_CANNY },
];

const aboutLinks = [
  { label: "My Story", href: "/about" },
  { label: "Donate", href: "/about", comingSoon: true },
  { label: "Join My Team", href: "#", comingSoon: true },
];

const standardLinks = [
  { label: "Email", href: "mailto:support@pathtochrist.life" },
  { label: "Privacy Policy", href: "/privacy-policy" },
  { label: "Terms of Service", href: "/terms-of-service" },
];

const Footer = () => {
  return (
    <footer className="border-t-2 bg-primary-100 border-primary-400 text-primary-800">
      <div className="container px-6 py-10 mx-auto max-w-[988px]">
        {/* Top Section: Navigation */}
        <nav aria-label="Footer Navigation" className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          {/* Features Section */}
          <section className="md:col-span-2">
            <h3 className="mb-4 font-bold uppercase text-md">Features</h3>
            <ul>
              {features.map((item, index) => (
                <li key={index} className="mb-2">
                  <a href={item.href} className="hover:underline">
                    {item.label}
                  </a>
                  {item.comingSoon && (
                    <span className="ml-2 inline-block text-xs font-semibold text-gray-500 border border-gray-500 rounded px-1">
                      Coming Soon!
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </section>

          {/* Connect Section */}
          <section>
            <h3 className="mb-4 font-bold uppercase text-md">Connect</h3>
            <ul>
              {connectLinks.map((item, index) => (
                <li key={index} className="mb-2">
                  <a href={item.href} className="hover:underline">
                    {item.label}
                  </a>
                </li>
              ))}
            </ul>
          </section>

          {/* About Section */}
          <section>
            <h3 className="mb-4 font-bold uppercase text-md">About</h3>
            <ul>
              {aboutLinks.map((item, index) => (
                <li key={index} className="mb-2">
                  <a href={item.href} className="hover:underline">
                    {item.label}
                  </a>
                  {item.comingSoon && (
                    <span className="ml-2 inline-block text-xs font-semibold text-gray-500 border border-gray-500 rounded px-1">
                      Coming Soon!
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </section>
        </nav>

        {/* Divider */}
        <hr className="w-full my-6 border-wise-dark" />

        {/* Bottom Section: Standard Links and Social Media */}
        <div className="flex flex-col items-center justify-between md:flex-row">
          <nav aria-label="Footer Standard Links" className="flex flex-wrap justify-center mb-4 md:mb-0">
            {standardLinks.map((item, index) => (
              <a key={index} href={item.href} className="mr-4 text-sm hover:underline">
                {item.label}
              </a>
            ))}
          </nav>

          <div className="flex space-x-6 mb-4 md:mb-0">
            <a
              href={process.env.REACT_APP_DISCORD_INVITE}
              aria-label="Discord"
              className="transition-colors hover:text-primary-600"
            >
              <FaDiscord size={20} />
            </a>
          </div>

          <p className="text-sm text-center md:text-right">
            © {new Date().getFullYear()} Path to Christ. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
