// src/pages/Settings/tabs/ProfileTab.js
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { FaEdit, FaUserAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { updateUserProfile, updateUserProfilePhoto } from "../../../store/actions/userActions";
import { updatePhotoURL } from "../../../store/reducers/userSlice";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebase/firebase";
import { setUserToCache } from "../../../utils/localCache";

const religions = ["Christianity", "Buddhism", "Hinduism", "Islam", "Judaism", "Sikhism", "Other"];
const denominations = [
    "Anglican",
    "Baptist",
    "Catholic",
    "Church of Jesus Christ of Latter-day Saints",
    "Eastern Orthodox",
    "Lutheran",
    "Methodist",
    "Non-denominational",
    "Pentecostal",
    "Presbyterian",
    "Seventh-day Adventist",
    "Quaker",
    "Wesleyan",
];

const languages = [
    { code: "en-us", name: "English" },
    { code: "pt-br", name: "Português" },
];

const ProfileTab = ({ user, setUser, setMessage, setError }) => {
    const dispatch = useDispatch();
    const [profile, setProfile] = useState({});
    const [selectedLanguage, setSelectedLanguage] = useState(user.language || "en-us");

    useEffect(() => {
        if (user) {
            setProfile({
                displayName: user.displayName || "",
                email: user.email || "",
                testimony: user.testimony || "",
                religion: user.religion || "Christianity",
                denomination: user.denomination || "",
                latterDaySaint: user.latterDaySaint || false,
                emailNotifications: user.emailNotifications || true,
                pushNotifications: user.pushNotifications || true,
                theme: user.theme || "meek",
                darkMode: user.darkMode || false,
            });
        }
    }, [user]);

    const handleChange = (field, value) => {
        setProfile((prev) => ({ ...prev, [field]: value }));
    };

    const handleSave = () => {
        const data = { uid: user.uid, ...profile, language: selectedLanguage };
        dispatch(updateUserProfile(data))
            .then(() => {
                setUserToCache({ ...user, ...data });
                setUser({ ...user, ...data });
                setMessage("Profile updated successfully!");
            })
            .catch((err) => {
                setError("Failed to update profile.");
                console.error(err);
            });
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        const refPath = ref(storage, `profileImages/${user.uid}`);
        const uploadTask = uploadBytesResumable(refPath, file);
        uploadTask.on(
            "state_changed",
            null,
            (error) => {
                setError("Image upload failed.");
                console.error(error);
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                dispatch(updateUserProfilePhoto({ uid: user.uid, photoURL: downloadURL }));
                // Use the imported action creator to update Redux
                dispatch(updatePhotoURL(downloadURL));
                setUserToCache({ ...user, photoURL: downloadURL });
                setUser({ ...user, photoURL: downloadURL });
                setMessage("Image updated.");
            }
        );
    };

    return (
        <div>
            <h3 className="mb-4 text-2xl font-bold">Public Profile</h3>
            <div className="mb-6">
                {/* Profile Image */}
                <label className="block mb-2 text-sm font-bold text-gray-700">Profile Image</label>
                <div className="relative inline-block mb-6 mr-0 md:mr-6 md:mb-0">
                    <img
                        src={user.photoURL || "https://via.placeholder.com/150"}
                        alt="User Avatar"
                        className="object-cover w-32 h-32 rounded-full"
                    />
                    <label
                        htmlFor="profile-image-upload"
                        className="absolute bottom-0 right-0 flex items-center justify-center w-10 h-10 text-white bg-blue-500 rounded-full cursor-pointer hover:bg-blue-600"
                    >
                        <FaEdit />
                        <input
                            id="profile-image-upload"
                            type="file"
                            className="hidden"
                            accept="image/*"
                            onChange={handleImageUpload}
                        />
                    </label>
                </div>

                {/* Display Name */}
                <label className="block mt-4 mb-2 text-sm font-bold text-gray-700">Display Name</label>
                <div className="relative flex items-center w-full border rounded-3xl">
                    <FaUserAlt className="absolute w-4 h-4 text-gray-400 left-3" />
                    <input
                        type="text"
                        value={profile.displayName}
                        onChange={(e) => handleChange("displayName", e.target.value)}
                        className="w-full py-2 pl-10 bg-meek-light rounded-3xl"
                        placeholder="Enter name"
                    />
                </div>
            </div>

            {/* Email */}
            <div className="mb-6">
                <label className="block mb-2 text-sm font-bold text-gray-700">Email</label>
                <div className="relative flex items-center w-full border rounded-3xl">
                    <MdEmail className="absolute w-5 h-5 text-gray-400 left-3" />
                    <input
                        type="email"
                        value={profile.email}
                        onChange={(e) => handleChange("email", e.target.value)}
                        className="w-full py-2 pl-10 bg-meek-light rounded-3xl"
                        placeholder="Enter email"
                    />
                </div>
            </div>

            {/* Religion */}
            <div className="mb-6">
                <label className="block mb-2 text-sm font-bold text-gray-700">Religion</label>
                <select
                    value={profile.religion}
                    onChange={(e) => handleChange("religion", e.target.value)}
                    className="w-full px-4 py-2 border bg-meek-light rounded-3xl"
                >
                    {religions.map((r, i) => (
                        <option key={i} value={r}>
                            {r}
                        </option>
                    ))}
                </select>
            </div>

            {/* Denomination (conditionally rendered) */}
            {profile.religion === "Christianity" && (
                <div className="mb-6">
                    <label className="block mb-2 text-sm font-bold text-gray-700">Denomination</label>
                    <select
                        value={profile.denomination}
                        onChange={(e) => handleChange("denomination", e.target.value)}
                        className="w-full px-4 py-2 border bg-meek-light rounded-3xl"
                    >
                        <option value="">Select a denomination</option>
                        {denominations.map((d, i) => (
                            <option key={i} value={d}>
                                {d}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {/* Church Toggle */}
            <div className="mb-6">
                <label className="block mb-2 text-sm font-bold text-gray-700">
                    Latter-day Saint Content
                </label>
                <div className="flex items-center text-gray-700">
                    <input
                        type="checkbox"
                        checked={profile.latterDaySaint}
                        onChange={(e) => handleChange("latterDaySaint", e.target.checked)}
                        className="mx-3"
                    />
                    Show Church content
                </div>
            </div>

            {/* Language Selector */}
            <div className="mb-6">
                <label className="block mb-2 text-sm font-bold text-gray-700">Language</label>
                <select
                    value={selectedLanguage}
                    onChange={(e) => setSelectedLanguage(e.target.value)}
                    className="w-full px-4 py-2 border bg-meek-light rounded-3xl"
                >
                    {languages.map((lang) => (
                        <option key={lang.code} value={lang.code}>
                            {lang.name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex justify-end">
                <button
                    onClick={handleSave}
                    className="w-full px-6 py-2 font-bold text-white sm:w-auto bg-meek-dark rounded-3xl hover:bg-primary-900"
                >
                    Save Changes
                </button>
            </div>
        </div>
    );
};

export default ProfileTab;
