import React from "react";
import Navbar from "../components/constants/Navbar";
import Footer from "../components/constants/Footer";

import { useParams } from "react-router-dom";
/* import { useState } from "react"; */
/* import axios from "axios"; */

const MarksOfDiscipleshipStudy = () => {
  const { category } = useParams(); // Get the category from the route
  /* const [studyGuideData, setStudyGuideData] = useState(null); */
  /* const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); */

  /* useEffect(() => {
    // Fetch the study guide data along with the question data for the given question ID
    const fetchStudyGuideData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/study-guides/${category}`
        );

        console.log("API Response:", response.data); // Debugging: Check API response

        setStudyGuideData(response.data.studyGuide);
      } catch (err) {
        setError("Failed to load study guide data.");
      } finally {
        setLoading(false);
      }
    };

    fetchStudyGuideData();
  }, [category]); */

  /* if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>; */

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900">
      <Navbar />

      {/* <div className="container py-8 mx-auto">
        {studyGuideData ? (
          <StudyGuideComponent
            title={studyGuideData.title}
            scriptures={studyGuideData.scriptures}
            discourses={studyGuideData.discourses}
            exercises={studyGuideData.exercises}
          />
        ) : (
          <p>No study guide found for this question.</p>
        )}
      </div> */}

      {/* Hero Section */}
      <section className="relative flex items-center justify-center h-[50vh] bg-cover bg-center bg-[url('https://cdn.midjourney.com/fd956385-08f2-48cf-b16e-427d026c9c8b/0_1.png')]">
        <div className="p-6 text-center text-white rounded backdrop-blur-sm bg-black/50">
          <h1 className="mb-4 text-5xl font-bold">Be Ye {category}</h1>
          <p className="mb-6 text-xl italic">
            "Be thou faithful unto death, and I will give thee a crown of life."
            – Revelation 2:10
          </p>
          <button className="px-6 py-3 font-semibold text-white bg-blue-500 rounded hover:bg-blue-600">
            Set a Goal to Be Faithful
          </button>
        </div>
      </section>

      {/* Scrollable Sections */}
      <div className="container p-4 mx-auto">
        {/* General Conference Talks */}
        <section className="space-y-2 sm:flex">
          <h2 className="mb-4 text-3xl font-bold text-gray-900 dark:text-gray-100">
            Talks
          </h2>
          <div className="mb-4 shrink-0">
            <img
              src="https://www.churchofjesuschrist.org/imgs/d71364c600cf1901798e9573ed3b9e2d2a58d030/full/1600%2C/0/default"
              aria-hidden="true"
              className="h-32 text-gray-300 bg-white border border-gray-300 sm:w-32"
            ></img>
          </div>
          <div>
            <h4 className="text-lg font-bold">Days Never to Be Forgotten</h4>
            <p className="mt-1">
              Upcoming moments will provide members of the Church everywhere
              with increased opportunities to share the glad tidings of the
              gospel of Jesus Christ.
            </p>
          </div>
          <div className="mb-4 shrink-0">
            <img
              src="https://www.churchofjesuschrist.org/imgs/9553a4d2e8f5950c2d187807a919ccead9001c9d/full/1600%2C/0/default"
              aria-hidden="true"
              className="h-32 text-gray-300 bg-white border border-gray-300 sm:w-32"
            ></img>
          </div>
          <div>
            <h4 className="text-lg font-bold">Following Christ</h4>
            <p className="mt-1">
            As followers of Christ, we teach and testify of Jesus Christ, our Perfect Role Model. So let us follow Him by forgoing contention.
            </p>
          </div>
        </section>

        {/* Scriptures Section */}
        <section className="my-12">
          <h2 className="mb-4 text-3xl font-bold text-gray-900 dark:text-gray-100">
            Scriptures
          </h2>
          <ul className="space-y-4">
            {["Revelation 2:10", "Alma 37:6", "Ether 12:27", "D&C 6:36"].map(
              (scripture, index) => (
                <li
                  key={index}
                  className="p-4 bg-white rounded shadow dark:bg-gray-800"
                >
                  <p className="text-lg text-gray-700 dark:text-gray-300">
                    {scripture}
                  </p>
                </li>
              )
            )}
          </ul>
        </section>

        {/* Prophetic Quotes Section */}
        <section className="mb-12">
          <h2 className="mb-4 text-3xl font-bold text-gray-900 dark:text-gray-100">
            Prophetic Quotes
          </h2>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            {Array(2)
              .fill(null)
              .map((_, index) => (
                <div
                  key={index}
                  className="flex items-center gap-4 p-6 bg-white rounded shadow dark:bg-gray-800"
                >
                  <div className="w-16 h-16 bg-gray-300 rounded-full dark:bg-gray-700"></div>
                  <div>
                    <p className="text-lg text-gray-700 dark:text-gray-300">
                      "Inspirational quote placeholder."
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      – Prophet Name
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </section>

        {/* Videos Section */}
        {/* <section className="mb-12">
          <h2 className="mb-4 text-3xl font-bold text-gray-900 dark:text-gray-100">
            Videos
          </h2>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {Array(3)
              .fill(null)
              .map((_, index) => (
                <div
                  key={index}
                  className="overflow-hidden bg-gray-300 rounded shadow aspect-w-16 aspect-h-9 dark:bg-gray-700"
                >
                  <p className="text-center text-gray-500">Video Placeholder</p>
                </div>
              ))}
          </div>
        </section> */}

        {/* Visual Media Section */}
        {/* <section className="mb-12">
          <h2 className="mb-4 text-3xl font-bold text-gray-900 dark:text-gray-100">
            Visual Media
          </h2>
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
            {Array(6)
              .fill(null)
              .map((_, index) => (
                <div
                  key={index}
                  className="overflow-hidden bg-gray-300 rounded dark:bg-gray-700 aspect-w-1 aspect-h-1"
                >
                  <p className="text-center text-gray-500">Image Placeholder</p>
                </div>
              ))}
          </div>
        </section> */}

        {/* Goal Setting Section */}
        <section className="mb-12">
          <h2 className="mb-4 text-3xl font-bold text-gray-900 dark:text-gray-100">
            Set a Goal
          </h2>
          <div className="p-6 bg-white rounded shadow dark:bg-gray-800">
            <p className="mb-4 text-lg text-gray-700 dark:text-gray-300">
              Create a specific, actionable goal to improve your faithfulness.
            </p>
            <input
              type="text"
              placeholder="E.g., Pray every morning for a month"
              className="w-full p-3 mb-4 border border-gray-300 rounded dark:border-gray-700"
            />
            <button className="px-6 py-3 font-semibold text-white bg-green-500 rounded hover:bg-green-600">
              Save Goal
            </button>
          </div>
        </section>

        {/* Community Feed Section */}
        {/* <section className="mb-12">
          <h2 className="mb-4 text-3xl font-bold text-gray-900 dark:text-gray-100">
            Community Thoughts
          </h2>
          <div className="space-y-4">
            {Array(3)
              .fill(null)
              .map((_, index) => (
                <div
                  key={index}
                  className="p-6 bg-white rounded shadow dark:bg-gray-800"
                >
                  <p className="mb-2 text-lg text-gray-700 dark:text-gray-300">
                    "User-generated thought or note placeholder."
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    – User Name
                  </p>
                </div>
              ))}
          </div>
        </section> */}
      </div>

      <Footer />
    </div>
  );
};

export default MarksOfDiscipleshipStudy;
