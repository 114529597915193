import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { ChevronDownIcon, UserIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

export default function Flyout({ menuItems, profileItems, user, openModal }) {
  return (
    <Popover className="relative h-8">
      <PopoverButton className="inline-flex items-center text-sm font-semibold leading-6 text-gray-900 gap-x-1">
        <ChevronDownIcon aria-hidden="true" className="w-7 h-7 mt-1" />
      </PopoverButton>

      <PopoverPanel
        transition
        className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="flex-auto w-screen max-w-md overflow-hidden text-sm leading-6 bg-white shadow-lg rounded-3xl ring-1 ring-gray-900/5">
          <div className="p-4">
            {menuItems.map((item) => (
              <div
                key={item.name}
                className="relative flex p-4 rounded-lg group gap-x-6 hover:bg-gray-50"
              >
                <div className="flex items-center justify-center flex-none mt-1 rounded-lg h-11 w-11 bg-gray-50 group-hover:bg-white">
                  <item.icon
                    aria-hidden="true"
                    className="w-6 h-6 text-gray-600 group-hover:text-indigo-600"
                  />
                </div>
                <div>
                  <a href={item.href} className="font-semibold text-gray-900">
                    {item.name}
                    <span className="absolute inset-0" />
                  </a>
                  {item.new && (
                    <span className="inline-flex items-center px-2 py-1 ml-2 text-xs font-medium text-purple-700 rounded-md bg-purple-50 ring-1 ring-inset ring-purple-700/10">
                      New
                    </span>
                  )}
                  {item.comingSoon && (
                    <span className="inline-flex items-center px-2 py-1 ml-2 text-xs font-medium text-red-700 rounded-md bg-red-50 ring-1 ring-inset ring-red-600/10">
                      Coming Soon
                    </span>
                  )}
                  <p className="mt-1 text-gray-600">{item.description}</p>
                </div>
              </div>
            ))}
          </div>

          {/* Profile/Auth Buttons */}
          <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
            {user ? (
              <div className="flex">
                {profileItems.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                  >
                    <item.icon
                      aria-hidden="true"
                      className="flex-none w-5 h-5 text-gray-400"
                    />
                    {item.name}
                  </Link>
                ))}
              </div>
            ) : (
              <div className="flex items-center justify-center space-x-2">
                <UserIcon className="flex-none w-6 h-6 text-gray-400" />
                <button
                  onClick={() => {
                    openModal();
                  }}
                  className="p-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-100"
                >
                  Login
                </button>
              </div>
            )}
          </div>
        </div>
      </PopoverPanel>
    </Popover>
  );
}
