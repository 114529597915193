// src/components/navigation/Navbar.js
import React, { useState, useCallback, useEffect, lazy, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, selectLanguage, setLanguage } from "../../store/reducers/userSlice";
import { auth } from "../../firebase/firebase";
import { Link } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

import Flyout from "../navigation/Flyout";
import MobileMenu from "../navigation/MobileMenu";
import {
  ArrowPathIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  GlobeAltIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/outline";
import { PhoneIcon, PlayCircleIcon } from "@heroicons/react/20/solid";
const AuthModal = lazy(() => import("../modals/AuthModal"));

const availableLanguages = [
  { code: "en-us", name: "English" },
  { code: "pt-br", name: "Português" },
];

const menuItems = [
  {
    name: "Self-Assessment",
    description: "Take the test to measure your spiritual growth",
    href: "/self-assessment",
    icon: ChartPieIcon,
    new: false,
    comingSoon: false,
    disabled: false,
  },
  {
    name: "Scripture Viewer",
    description: "Read and study the scriptures",
    href: "/scriptures",
    icon: ArrowPathIcon,
    new: true,
    comingSoon: false,
    disabled: false,
  },
  {
    name: "What Lack I Yet",
    description: "Spiritual Checklist to measure your progress",
    href: "/what-lack-i-yet",
    icon: CursorArrowRaysIcon,
    comingSoon: true,
    disabled: true,
  },
  {
    name: "Study Plans",
    description: "Personalized study plans to help you grow",
    href: "#",
    icon: FingerPrintIcon,
    comingSoon: true,
    disabled: true,
  },
  {
    name: "Global Discipleship",
    description: "View & compare insights from all users",
    href: "#",
    icon: SquaresPlusIcon,
    comingSoon: true,
    disabled: true,
  },
];

const profileItems = [
  { name: "Profile", href: "/profile", icon: PlayCircleIcon },
  { name: "My Stats", href: "/results", icon: PhoneIcon },
  { name: "Account Settings", href: "/settings", icon: PhoneIcon },
];

const secondaryMenuItems = [
  { name: "About", href: "/about", icon: ArrowPathIcon },
  { name: "Contact", href: "/contact", icon: ArrowPathIcon },
];

const USER_MENU_ITEM_CLASS =
  "block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100";
const LANGUAGE_MENU_ITEM_CLASS = "block w-full px-4 py-2 text-left";

const Navbar = () => {
  const dispatch = useDispatch();

  // First try to get the user from Redux...
  const reduxUser = useSelector(selectUser);
  // ...and if Redux returns null, check localStorage.
  const cachedUser = JSON.parse(localStorage.getItem("user"));
  const user = reduxUser || cachedUser;

  const language = useSelector(selectLanguage);

  console.log("User object:", user);
  console.log("User photo URL: ", user?.photoURL);

  const handleLanguageChange = useCallback(
    (languageCode) => {
      dispatch(setLanguage(languageCode));
    },
    [dispatch]
  );

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const [hasShadow, setHasShadow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setHasShadow(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleLogOut = useCallback(() => {
    auth
      .signOut()
      .then(() => setError(null))
      .catch((error) => {
        setError("Error signing out: " + error.message);
        console.error("Error signing out:", error);
      });
  }, []);

  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);

  return (
    <header
      className={`sticky top-0 z-50 bg-white transition-shadow duration-300 ${hasShadow ? "shadow-md" : ""
        }`}
    >
      <div className="h-1 bg-gradient-to-r from-primary-400 to-primary-600" />
      <nav
        className="flex items-center justify-between p-4 max-w-[988px] mx-auto lg:px-8"
        aria-label="Global"
      >
        {/* Left section with Logo and Site Name */}
        <div className="flex items-center align-center space-x-6">
          <Link to="/" className="flex items-center space-x-2">
            <img
              className="w-auto h-8"
              src="https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2Fdisciplesheep-nobg.png?alt=media&token=dedeaedd-b5b9-495e-9802-7dffd423c9d1"
              alt="Discipleship Logo"
            />
          </Link>
          <div className="hidden items-center lg:flex">
            <h1 className="text-2xl font-bold text-gray-900">Discipleship</h1>
            <Flyout menuItems={menuItems} profileItems={profileItems} user={user} openModal={openModal} />
          </div>
        </div>

        {/* Right section with CTA and Auth Buttons */}
        <div className="hidden lg:flex lg:items-center lg:space-x-4">
          {/* Language Selector */}
          <div className="flex items-center bg-transparent rounded-md">
            <Menu as="div" className="relative">
              <MenuButton className="flex items-center text-gray-400 px-3 py-2 space-x-2 bg-white rounded-md">
                <GlobeAltIcon className="w-5 h-5" />
                <span>
                  {availableLanguages.find((l) => l.code === language)?.name}
                </span>
              </MenuButton>
              <MenuItems className="absolute right-0 z-10 w-48 py-1 mt-2 transition origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {availableLanguages.map(({ code, name }) => (
                  <MenuItem key={code}>
                    {({ active }) => (
                      <button
                        onClick={() => handleLanguageChange(code)}
                        className={`${LANGUAGE_MENU_ITEM_CLASS} ${active ? "bg-gray-200" : ""}`}
                      >
                        {name}
                      </button>
                    )}
                  </MenuItem>
                ))}
              </MenuItems>
            </Menu>
          </div>
          {/* CTA Button */}
          <Link
            to="/self-assessment"
            className="px-4 py-2 font-semibold text-white transition-colors rounded-2xl bg-primary-500 hover:bg-primary-600"
          >
            Take the Test
          </Link>
          
          {/* User Authentication */}
          {user ? (
            <div className="flex items-center space-x-4">
              <Menu as="div" className="relative ml-3">
                <div>
                  <MenuButton className="relative flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    <img
                      alt={`Profile picture of ${user.displayName || "user"}`}
                      src={
                        user.photoURL ||
                        "https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/profilePictures%2Fprofile.png?alt=media&token=00ad0577-b2e7-4e94-ab0b-ac217a14a0d1"
                      }
                      className="w-8 h-8 rounded-full"
                    />
                  </MenuButton>
                </div>
                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <MenuItem>
                    <Link to="/dashboard" className={USER_MENU_ITEM_CLASS}>
                      Dashboard
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/profile" className={USER_MENU_ITEM_CLASS}>
                      Your Profile
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/results" className={USER_MENU_ITEM_CLASS}>
                      Your Stats
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/settings" className={USER_MENU_ITEM_CLASS}>
                      Settings
                    </Link>
                  </MenuItem>
                  {user?.isAdmin && (
                    <MenuItem>
                      <Link to="/admin" className={USER_MENU_ITEM_CLASS}>
                        Admin Dashboard
                      </Link>
                    </MenuItem>
                  )}
                  <MenuItem>
                    <button
                      className={`${USER_MENU_ITEM_CLASS} w-full text-left`}
                      onClick={handleLogOut}
                      aria-label="Log Out"
                    >
                      Log Out
                    </button>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          ) : (
            <button
              className="font-semibold transition-colors text-goldenrod-600 hover:text-goldenrod-800"
              onClick={openModal}
              aria-label="Log In"
            >
              Log In
            </button>
          )}
        </div>

        {/* Mobile Menu */}
        <div className="lg:hidden">
          <MobileMenu
            primaryMenuItems={menuItems}
            secondaryMenuItems={secondaryMenuItems}
            profileItems={profileItems}
            user={user}
            openModal={openModal}
            handleLogOut={handleLogOut}
          />
        </div>
      </nav>

      {error && (
        <div className="mx-auto max-w-7xl p-2 text-center text-red-600">
          {error}
        </div>
      )}

      <Suspense fallback={<div>Loading modal...</div>}>
        {showModal && <AuthModal onClose={closeModal} />}
      </Suspense>
    </header>
  );
};

Navbar.propTypes = {};

export default Navbar;
