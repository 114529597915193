// src/pages/Profile.js
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../components/constants/Navbar";
import Footer from "../components/constants/Footer";
import ProfileInfoCard from "../components/ProfileInfoCard";
import { fetchUserProfileData, updateUserProfile } from "../store/actions/userActions";
import { selectUser, selectProfileData } from "../store/reducers/userSlice";
import { FaMedal, FaCertificate } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";

const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const profileDataFromStore = useSelector(selectProfileData);

  // Local state for editable profile info
  const [editing, setEditing] = useState(false);
  const [profileData, setProfileData] = useState({
    email: user?.email || "",
    displayName: user?.displayName || "",
    photoURL:
      user?.photoURL ||
      "https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/profilePictures%2Fprofile.png?alt=media&token=00ad0577-b2e7-4e94-ab0b-ac217a14a0d1",
    testimony: profileDataFromStore?.testimony || "",
    spiritualStage: profileDataFromStore?.spiritualStage || "Seeking",
    badges: profileDataFromStore?.badges || [],
    achievements: profileDataFromStore?.achievements || [],
  });

  // Fetch profile data if not already present
  useEffect(() => {
    if (user?.uid && !profileDataFromStore) {
      dispatch(fetchUserProfileData(user.uid));
    }
  }, [user, profileDataFromStore, dispatch]);

  // Update local state when store profileData changes
  useEffect(() => {
    if (profileDataFromStore) {
      setProfileData((prev) => ({
        ...prev,
        testimony: profileDataFromStore.testimony || "",
        spiritualStage: profileDataFromStore.spiritualStage || "Seeking",
        badges: profileDataFromStore.badges || [],
        achievements: profileDataFromStore.achievements || [],
      }));
    }
  }, [profileDataFromStore]);

  const handleProfileSave = () => {
    dispatch(updateUserProfile(profileData));
    setEditing(false);
  };

  const handleChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const spiritualProgress = {
    Seeking: 25,
    Growing: 50,
    Strengthening: 75,
    Flourishing: 100,
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />

      {/* Enhanced Profile Header / Banner */}
      <header className="relative">
        <div
          className="h-64 bg-cover bg-center relative"
          style={{
            backgroundImage:
              "url('https://via.placeholder.com/1200x400?text=Your+Cover+Image')",
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50"></div>
        </div>
        <div className="absolute left-1/2 transform -translate-x-1/2 -bottom-16 flex flex-col items-center">
          <img
            src={profileData.photoURL}
            alt={profileData.displayName}
            className="w-32 h-32 rounded-full border-4 border-white shadow-2xl"
          />
          <h2 className="mt-4 text-3xl font-bold text-white drop-shadow-lg">
            {profileData.displayName}
          </h2>
        </div>
      </header>

      <div className="container pt-24 pb-8 mx-auto space-y-8">
        {/* Profile Info Card */}
        <div className="bg-white shadow rounded-lg p-6">
          <ProfileInfoCard
            user={user}
            profileData={profileData}
            fetchUserProfile={(uid) => dispatch(fetchUserProfileData(uid))}
            setProfileData={setProfileData}
            editing={editing}
            setEditing={setEditing}
            handleProfileSave={handleProfileSave}
            handleChange={handleChange}
          />
        </div>

        {/* Spiritual Progress Card */}
        <div className="bg-white shadow rounded-lg p-6">
          <h3 className="mb-4 text-xl font-semibold text-gray-800">
            Spiritual Progress
          </h3>
          <div className="w-full bg-gray-200 rounded-full overflow-hidden">
            <div
              className="p-2 text-xs font-medium leading-none text-center text-white bg-green-500 rounded-full transition-all duration-700 ease-in-out"
              style={{
                width: `${spiritualProgress[profileData.spiritualStage]}%`,
              }}
            >
              {spiritualProgress[profileData.spiritualStage]}%
            </div>
          </div>
        </div>

        {/* Badges Card */}
        <div className="bg-white shadow rounded-lg p-6">
          <h3 className="mb-4 text-xl font-semibold text-gray-800">
            Badges Earned
          </h3>
          <div className="grid grid-cols-2 gap-6 md:grid-cols-4">
            {profileData.badges.length > 0 ? (
              profileData.badges.map((badge, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center p-4 bg-blue-100 rounded-lg shadow hover:shadow-lg transition-shadow"
                >
                  <FaMedal className="text-4xl text-blue-600" />
                  <span className="mt-2 text-lg font-medium">{badge}</span>
                </div>
              ))
            ) : (
              <p className="text-gray-600">No badges earned yet.</p>
            )}
          </div>
        </div>

        {/* Achievements Card */}
        <div className="bg-white shadow rounded-lg p-6">
          <h3 className="mb-4 text-xl font-semibold text-gray-800">
            Achievements
          </h3>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {profileData.achievements.length > 0 ? (
              profileData.achievements.map((achievement, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center p-4 bg-green-100 rounded-lg shadow hover:shadow-lg transition-shadow"
                >
                  <FaCertificate className="text-4xl text-green-600" />
                  <span className="mt-2 text-lg font-medium">
                    {achievement}
                  </span>
                </div>
              ))
            ) : (
              <p className="text-gray-600">No achievements yet.</p>
            )}
          </div>
        </div>

        {/* Friends Activity Card */}
        <div className="bg-white shadow rounded-lg p-6">
          <h3 className="mb-4 text-xl font-semibold text-gray-800">
            Friends Activity
          </h3>
          <div className="space-y-4">
            {/* Example activity item */}
            <div className="flex items-center justify-between p-4 bg-gray-100 rounded-lg shadow hover:shadow-xl transition-shadow">
              <div className="flex items-center">
                <img
                  src="https://via.placeholder.com/50"
                  alt="Friend Avatar"
                  className="w-12 h-12 rounded-full"
                />
                <div className="ml-4">
                  <p className="font-semibold text-gray-700">
                    John Doe
                  </p>
                  <p className="text-sm text-gray-500">
                    Just earned a new badge!
                  </p>
                </div>
              </div>
              <button className="flex items-center px-3 py-1 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors">
                <FiUsers className="mr-2" />
                Celebrate
              </button>
            </div>
            {/* Additional dynamic activity items can be rendered here */}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Profile;
