// TODO: Add URL handling & auto-save upon upload
// TODO: Add goal handling
// TODO: It should grab the selected mark of discipleship from the URL and display the study guide for that mark.

import React from "react";
import Navbar from "../components/constants/Navbar";

import { PlusIcon, InformationCircleIcon } from "@heroicons/react/20/solid";
import { FaBook, FaPlaceOfWorship, FaShare } from "react-icons/fa";

import { getSectionColor, getModIcon } from "../utils/getColor";

const markInfo = {
  title: "Loving",
  color: "bg-blue-500",
  icon: "FaithfulIcon",
  imageUrl:
    "https://cdn.midjourney.com/fd956385-08f2-48cf-b16e-427d026c9c8b/0_1.png",
  definition:
    "The highest, noblest, strongest kind of love, not merely affection; the pure love of Christ. It is never used to denote alms or deeds of benevolence, although it may be a prompting motive",
};

const presetGoals = [
  {
    name: "Read scripture daily",
    category: "Daily Goal",
    color: "bg-green-100",
  },
  {
    name: "Weekly scripture study",
    category: "Weekly Goal",
    color: "bg-yellow-100",
  },
  {
    name: "Attend church services regularly",
    category: "Long-Term Goal",
    color: "bg-red-100",
  },
];

const Study = () => {
  const headerImg = markInfo.imageUrl;
  const currentMoD = markInfo.title;
  const ModIcon = getModIcon(currentMoD);
  const Icon = ModIcon || null;

  return (
    <div className="p-6 space-y-8">
      <Navbar />

      {/* Header Section */}
      <div
        style={{ backgroundColor: getSectionColor(currentMoD) }}
        className="relative overflow-hidden isolate bg-gradient-to-b from-indigo-100/20"
      >
        <div
          aria-hidden="true"
          className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
        />
        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <div className="max-w-2xl mx-auto lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-8 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            <div className="flex items-center xl:col-start-1 xl:row-start-1">
              <Icon className="w-10 h-10 p-0.5 mt-2 mr-1" aria-hidden="true" />
              <h1 className="max-w-2xl text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
                {markInfo.title}
              </h1>
            </div>
            <div className="max-w-xl mt-6 lg:mt-0 xl:col-end-1 xl:row-start-1">
              <p className="text-lg font-medium text-gray-500 sm:text-xl">
                {markInfo.definition}
              </p>
            </div>
            <img
              alt=""
              src={headerImg}
              className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
            />
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 h-24 -z-10 bg-gradient-to-t from-white sm:h-32" />
      </div>

      {/* Study Guide Section Header */}
      <div className="flex items-center justify-between py-5 border-b border-gray-200">
        <div className="flex items-center">
          <h2 className="text-2xl font-semibold text-gray-900">
            Your Study Guide
          </h2>
          <span
            className="ml-2 text-gray-400 cursor-help"
            title="This page asks you to build your own study guide for the mark of discipleship in question, which allows people to deeply study the topic and then save the study guide to either study more in the future or share with friends."
          >
            <InformationCircleIcon className="w-5 h-5" aria-hidden="true" />
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <button
            type="button"
            className="inline-flex items-center px-2 py-1 text-sm text-gray-600 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            <FaShare className="w-4 h-4" aria-hidden="true" />
          </button>
        </div>
      </div>

      {/* Study Guide Content Sections */}
      <div className="space-y-8">
        {/* Conference Talk Section */}
        <div>
          <h3 className="mb-4 text-xl font-bold text-center">
            Conference Talk
          </h3>
          <div className="p-6 text-center border-2 border-dashed rounded-lg">
            <FaPlaceOfWorship
              className="w-8 h-8 mx-auto mb-2 text-gray-400"
              aria-hidden="true"
            />
            <p className="mb-4 text-gray-500">
              Paste a link to a general conference talk.
            </p>
            <div className="flex items-center justify-center space-x-2">
              <input
                type="url"
                placeholder="Talk URL"
                className="w-64 px-3 py-2 border rounded-md"
              />
            </div>
          </div>
        </div>

        {/* Scriptures Section */}
        <div>
          <h3 className="mb-4 text-xl font-bold text-center">
            Scripture References
          </h3>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            {[0, 1, 2].map((item) => (
              <div
                key={item}
                className="p-6 text-center border-2 border-dashed rounded-lg"
              >
                <FaBook
                  className="w-8 h-8 mx-auto mb-2 text-gray-400"
                  aria-hidden="true"
                />
                <p className="mb-4 text-gray-500">
                  Paste a scripture URL or reference
                </p>
                <input
                  type="text"
                  placeholder="Scripture URL or text"
                  className="w-full px-3 py-2 border rounded-md"
                />
              </div>
            ))}
          </div>
        </div>

        {/* Notes Section */}
        <div className="p-6 border-2 border-dashed rounded-lg">
          <h3 className="mb-2 text-xl font-bold">Your Notes</h3>
          <textarea
            placeholder="Type your thoughts, impressions, and questions here..."
            rows="4"
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        {/* Goals Section */}
        <div className="max-w-lg p-6 mx-auto border rounded-lg shadow-sm">
          <div className="text-center">
            <svg
              fill="none"
              stroke="currentColor"
              viewBox="0 0 48 48"
              aria-hidden="true"
              className="w-12 h-12 mx-auto text-gray-400"
            >
              <path
                d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h2 className="mt-2 text-base font-semibold text-gray-900">
              Add Goals
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              You haven't added any goals to your study guide yet. Select a goal
              to add it to your list.
            </p>
          </div>
          <form action="#" className="flex mt-6">
            <input
              name="goal"
              type="text"
              placeholder="Enter a goal description"
              aria-label="Goal description"
              className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:outline-indigo-600"
            />
            <button
              type="submit"
              className="px-3 py-2 ml-4 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm shrink-0 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <PlusIcon className="w-5 h-5 mr-1" aria-hidden="true" />
              Add Goal
            </button>
          </form>
          <div className="mt-10">
            <h3 className="text-sm font-medium text-gray-500">Preset Goals</h3>
            <ul
              role="list"
              className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200"
            >
              {presetGoals.map((goal, idx) => (
                <li
                  key={idx}
                  className="flex items-center justify-between py-4 space-x-3"
                >
                  <div className="flex items-center flex-1 min-w-0 space-x-3">
                    <div className="shrink-0">
                      <span
                        className={`inline-block h-10 w-10 rounded-full ${goal.color}`}
                      ></span>
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {goal.name}
                      </p>
                      <p className="text-sm text-gray-500 truncate">
                        {goal.category}
                      </p>
                    </div>
                  </div>
                  <div className="shrink-0">
                    <button
                      type="button"
                      className="inline-flex items-center gap-x-1.5 text-sm font-semibold text-gray-900"
                    >
                      <PlusIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                      Add <span className="sr-only">{goal.name}</span>
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Additional Links Section */}
        <div className="flex items-center justify-between py-5 border-b border-gray-200">
          <h3 className="text-2xl font-semibold text-gray-900">
            Additional Links
          </h3>
        </div>
        <div className="p-6 space-y-2 text-center border-2 border-dashed rounded-lg">
          <p className="mb-4 text-gray-500">
            Add any additional resources (talks, scriptures, videos, images,
            etc.)
          </p>
          <div className="flex items-center justify-center space-x-2">
            <input
              type="url"
              placeholder="Resource URL"
              className="w-64 px-3 py-2 border rounded-md"
            />
          </div>
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 ml-2 text-white bg-indigo-600 rounded-md"
          >
            <PlusIcon className="w-5 h-5 mr-1" aria-hidden="true" />
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default Study;
