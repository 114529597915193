import { createBrowserRouter } from "react-router-dom";
import About from "./pages/About";
import AdminDashboard from "./pages/admin/AdminDashboard";
import AuthPage from "./pages/AuthPage";
import ErrorPage from "./pages/ErrorPage";
import Dashboard from "./pages/Dashboard";
import Main from "./pages/Main";
import MarksOfDiscipleship from "./pages/MarksOfDiscipleship";
import MarksOfDiscipleshipStudy from "./pages/MarksOfDiscipleshipStudy";
import NotFound from "./pages/NotFound";
import PageTracker from "./components/PageTracker";
import PrivateRoute from "./components/PrivateRoute";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Profile from "./pages/Profile";
import Results from "./pages/Results";
import ScriptureViewer from "./components/scripture-viewer/ScriptureViewer";
import SelfAssessment from "./pages/SelfAssessment";
import SettingsPage from "./pages/settings/SettingsPage";
import Study from "./pages/Study";
import StudyGuide from "./pages/StudyGuide";
import TermsOfService from "./pages/TermsOfService";
import TriviaGame from "./pages/TriviaGame";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PageTracker>
        <Main />
      </PageTracker>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/about",
    element: (
      <PageTracker>
        <About />
      </PageTracker>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin",
    element: (
      <PrivateRoute isAdminOnly={true}>
        <PageTracker>
          <AdminDashboard />
        </PageTracker>
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/auth",
    element: (
      <PageTracker>
        <AuthPage />
      </PageTracker>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/dashboard",
    element: (
      <PrivateRoute>
        <PageTracker>
          <Dashboard />
        </PageTracker>
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/marks-of-discipleship",
    element: (
      <PageTracker>
        <MarksOfDiscipleship />
      </PageTracker>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/marks-of-discipleship/study/:category",
    element: (
      <PageTracker>
        <MarksOfDiscipleshipStudy />
      </PageTracker>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/privacy-policy",
    element: (
      <PageTracker>
        <PrivacyPolicy />
      </PageTracker>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/profile",
    element: (
      <PrivateRoute>
        <PageTracker>
          <Profile />
        </PageTracker>
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    // Consolidated Scripture Viewer route
    path: "/scriptures/:volume?/:book?/:chapter?",
    element: (
      <PageTracker>
        <ScriptureViewer />
      </PageTracker>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/results",
    element: (
      <PrivateRoute>
        <PageTracker>
          <Results />
        </PageTracker>
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/self-assessment",
    element: (
      <PrivateRoute>
        <PageTracker>
          <SelfAssessment />
        </PageTracker>
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/settings",
    element: (
      <PrivateRoute>
        <PageTracker>
          <SettingsPage />
        </PageTracker>
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/study",
    element: (
      <PageTracker>
        <Study />
      </PageTracker>
    ),
  },
  {
    path: "/study-guide/:category/:questionId",
    element: (
      <PageTracker>
        <StudyGuide />
      </PageTracker>
    ),
  },
  {
    path: "/study-guide/:category",
    element: (
      <PageTracker>
        <MarksOfDiscipleshipStudy />
      </PageTracker>
    ),
  },
  {
    path: "/tos",
    element: (
      <PageTracker>
        <TermsOfService />
      </PageTracker>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/trivia",
    element: (
      <PageTracker>
        <TriviaGame />
      </PageTracker>
    ),
    errorElement: <ErrorPage />,
  },
  // Utility pages
  {
    path: "/404",
    element: (
      <PageTracker>
        <NotFound />
      </PageTracker>
    ),
  },
  {
    path: "*",
    element: (
      <PageTracker>
        <NotFound />
      </PageTracker>
    ),
  },
]);

export default router;
