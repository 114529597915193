// src/pages/Settings/tabs/PrivacyTab.js
import React, { useState } from "react";

const PrivacyTab = ({ user, setUser, setMessage }) => {
    const [settings, setSettings] = useState({
        showEmail: user.showEmail ?? true,
        showProfileToPublic: user.showProfileToPublic ?? true,
    });

    const handleChange = (field, value) => {
        setSettings((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleSave = () => {
        setUser((prev) => ({ ...prev, ...settings }));
        setMessage("Privacy settings saved! (Functionality coming soon)");
        // Future: send to backend
    };

    return (
        <div>
            <h3 className="mb-4 text-2xl font-bold">Privacy Settings (Coming Soon!)</h3>

            {/* Show Email */}
            <div className="mb-6">
                <label className="block mb-2 text-sm font-bold text-gray-700">Show Email to Public</label>
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        checked={settings.showEmail}
                        onChange={(e) => handleChange("showEmail", e.target.checked)}
                        className="mr-2"
                    />
                    Make email visible
                </div>
            </div>

            {/* Show Profile */}
            <div className="mb-6">
                <label className="block mb-2 text-sm font-bold text-gray-700">Show Profile to Public</label>
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        checked={settings.showProfileToPublic}
                        onChange={(e) => handleChange("showProfileToPublic", e.target.checked)}
                        className="mr-2"
                    />
                    Make profile visible
                </div>
            </div>

            <div className="flex justify-end">
                <button
                    onClick={handleSave}
                    className="w-full px-6 py-2 font-bold text-white sm:w-auto bg-meek-dark rounded-3xl hover:bg-primary-900"
                >
                    Save Privacy Settings
                </button>
            </div>
        </div>
    );
};

export default PrivacyTab;
