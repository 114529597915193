// src/pages/Settings/tabs/PreferencesTab.js
import React from "react";

const PreferencesTab = ({ user, setUser, setMessage }) => {
    const handleChange = (field, value) => {
        setUser((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleSave = () => {
        setMessage("Preferences saved! (Functionality coming soon)");
        // Future: send updated preferences to backend
    };

    return (
        <div>
            <h3 className="mb-4 text-2xl font-bold">User Preferences (Coming Soon!)</h3>

            {/* Theme */}
            <div className="mb-6">
                <label className="block mb-2 text-sm font-bold text-gray-700">Theme</label>
                <select
                    value={user.theme}
                    onChange={(e) => handleChange("theme", e.target.value)}
                    className="w-full px-4 py-2 border bg-meek-light rounded-3xl"
                >
                    <option value="faithful">Faithful</option>
                    <option value="loving">Loving</option>
                    <option value="meek">Meek</option>
                </select>
            </div>

            {/* Dark Mode */}
            <div className="mb-6">
                <label className="block mb-2 text-sm font-bold text-gray-700">Dark Mode</label>
                <select
                    value={user.darkMode ? "dark" : "light"}
                    onChange={(e) =>
                        handleChange("darkMode", e.target.value === "dark")
                    }
                    className="w-full px-4 py-2 border bg-meek-light rounded-3xl"
                >
                    <option value="light">Light</option>
                    <option value="dark">Dark</option>
                    <option value="system">Faithful</option>
                </select>
            </div>

            {/* Email Notifications */}
            <div className="mb-6">
                <label className="block mb-2 text-sm font-bold text-gray-700">Email Notifications</label>
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        checked={user.emailNotifications}
                        onChange={(e) => handleChange("emailNotifications", e.target.checked)}
                        className="mr-2"
                    />
                    Enable email notifications
                </div>
            </div>

            <div className="flex justify-end">
                <button
                    onClick={handleSave}
                    className="w-full px-6 py-2 font-bold text-white sm:w-auto bg-meek-dark rounded-3xl hover:bg-primary-900"
                >
                    Save Preferences
                </button>
            </div>
        </div>
    );
};

export default PreferencesTab;
