import React from "react";
import { useState, useEffect } from "react";

const IntroScreen = ({ onStart }) => {
    return (
        <section className="min-h-screen bg-primary-100 dark:bg-gray-900 flex flex-col items-center justify-center text-center p-6">
            <div className="max-w-3xl">
                <h2 className="text-4xl md:text-5xl font-heading text-primary mb-4">
                    Welcome to Discipleship Trivia
                </h2>
                <p className="text-lg font-body text-gray-700 dark:text-gray-300 mb-6">
                    Test your knowledge, challenge your faith, and discover inspiring insights from Bible stories and church history.
                </p>
                <blockquote className="italic text-quaternary dark:text-quaternary-200 mb-8">
                    “For the word of God is alive and active. Sharper than any double-edged sword…” – Hebrews 4:12
                </blockquote>
                <button
                    onClick={onStart}
                    className="px-8 py-3 bg-secondary hover:bg-secondary-400 text-white font-body rounded-full shadow-lg transition-transform duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-secondary"
                >
                    Start Trivia
                </button>
            </div>
        </section>
    );
};

const QuestionDisplay = ({ question, category, timer }) => {
    return (
        <div className="max-w-xl mx-auto p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
            <div className="flex justify-between items-center mb-4">
                {/* Category Label */}
                <span className="text-sm font-bold text-secondary uppercase">
                    {category}
                </span>
                {/* Timer Display */}
                <div className="flex items-center space-x-2">
                    <svg
                        className="w-6 h-6 text-info"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 8v4l3 3"
                        />
                    </svg>
                    <span className="text-sm font-body text-gray-600 dark:text-gray-300">
                        {timer}s
                    </span>
                </div>
            </div>
            <h2 className="text-2xl md:text-3xl font-heading text-gray-800 dark:text-gray-100 mb-6">
                {question}
            </h2>
        </div>
    );
};

// A single answer option component.
const AnswerOption = ({
    id,
    text,
    isSelected,
    isCorrect,
    disabled,
    onSelect,
    showFeedback,
}) => {
    // Base styling for each answer card.
    let baseClasses =
        "cursor-pointer p-4 rounded-lg border-2 transition transform duration-300 ease-in-out";
    let defaultClasses = "bg-white dark:bg-gray-700 border-gray-300 hover:scale-105";
    let selectedClasses = "";

    // Apply feedback styling if answers are disabled (after selection)
    if (showFeedback && isSelected) {
        selectedClasses = isCorrect
            ? "border-success animate-bounce" // Correct answer animation.
            : "border-danger animate-shake"; // Incorrect answer animation (custom, define animate-shake in your config).
    } else if (isSelected) {
        selectedClasses = "border-secondary";
    }

    return (
        <div
            className={`${baseClasses} ${defaultClasses} ${selectedClasses} ${disabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
            onClick={() => !disabled && onSelect(id)}
        >
            <p className="text-lg font-body text-gray-800 dark:text-gray-100">{text}</p>
        </div>
    );
};

// Main AnswerOptions component to display a grid of answer choices.
const AnswerOptions = ({
    options,
    onAnswerSelect,
    selectedAnswer,
    correctAnswerId,
    disabled,
}) => {
    return (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {options.map((option) => (
                <AnswerOption
                    key={option.id}
                    id={option.id}
                    text={option.text}
                    isSelected={selectedAnswer === option.id}
                    isCorrect={correctAnswerId === option.id}
                    disabled={disabled}
                    onSelect={onAnswerSelect}
                    showFeedback={disabled} // Once disabled, show correct/incorrect feedback.
                />
            ))}
        </div>
    );
};

const Timer = ({ duration, onComplete }) => {
    const [timeLeft, setTimeLeft] = useState(duration);
    const radius = 45; // Radius for the SVG circle.
    const circumference = 2 * Math.PI * radius;

    useEffect(() => {
        if (timeLeft === 0) {
            if (onComplete) onComplete();
            return;
        }
        const interval = setInterval(() => {
            setTimeLeft((prevTime) => Math.max(prevTime - 1, 0));
        }, 1000);
        return () => clearInterval(interval);
    }, [timeLeft, onComplete]);

    // Calculate progress for the circular stroke animation.
    const progress = timeLeft / duration;
    const strokeDashoffset = circumference - progress * circumference;

    // Transition color based on remaining time.
    let timerColor = "text-info"; // Default color (info).
    if (progress <= 0.33) {
        timerColor = "text-danger"; // Low time = danger.
    } else if (progress <= 0.66) {
        timerColor = "text-warning"; // Mid time = warning.
    }

    return (
        <div className="flex flex-col items-center justify-center">
            <svg className="rotate-[-90deg]" width="120" height="120">
                {/* Background circle */}
                <circle
                    className="text-gray-300 dark:text-gray-600"
                    strokeWidth="8"
                    stroke="currentColor"
                    fill="transparent"
                    r={radius}
                    cx="60"
                    cy="60"
                />
                {/* Animated progress circle */}
                <circle
                    className={`${timerColor}`}
                    strokeWidth="8"
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                    strokeLinecap="round"
                    stroke="currentColor"
                    fill="transparent"
                    r={radius}
                    cx="60"
                    cy="60"
                />
            </svg>
            <span className="mt-4 text-2xl font-heading text-gray-800 dark:text-gray-100">
                {timeLeft}s
            </span>
        </div>
    );
};

const ScoreProgressTracker = ({ currentScore, currentQuestion, totalQuestions }) => {
    const progressPercent = (currentQuestion / totalQuestions) * 100;

    return (
        <div className="max-w-xl mx-auto p-4">
            <div className="flex justify-between items-center mb-4">
                {/* Score Display */}
                <div className="flex flex-col items-center">
                    <span className="text-lg font-heading text-gray-800 dark:text-gray-100">Score</span>
                    <span className="text-3xl font-bold text-primary">{currentScore}</span>
                </div>
                {/* Question Progress Display */}
                <div className="flex flex-col items-center">
                    <span className="text-lg font-heading text-gray-800 dark:text-gray-100">Question</span>
                    <span className="text-3xl font-bold text-secondary">
                        {currentQuestion}/{totalQuestions}
                    </span>
                </div>
            </div>
            {/* Progress Bar */}
            <div className="w-full bg-gray-300 dark:bg-gray-600 rounded-full h-4 relative overflow-hidden">
                <div
                    className="bg-quaternary h-full transition-all duration-500"
                    style={{ width: `${progressPercent}%` }}
                ></div>
            </div>
            <div className="text-right mt-2 text-sm font-body text-gray-600 dark:text-gray-300">
                {progressPercent.toFixed(0)}% Completed
            </div>
        </div>
    );
};

const FeedbackModal = ({ isOpen, onClose, isCorrect, explanation, bonusMessage }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            {/* Background overlay */}
            <div
                className="absolute inset-0 bg-black opacity-50"
                onClick={onClose}
            ></div>

            {/* Modal content */}
            <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-2xl max-w-md w-full mx-4 p-6 animate-fadeIn">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-heading text-primary">
                        {isCorrect ? "Correct!" : "Oops!"}
                    </h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 text-3xl leading-none">
                        &times;
                    </button>
                </div>

                <div className="mb-4 flex items-center space-x-2">
                    {isCorrect ? (
                        <svg
                            className="w-8 h-8 text-success animate-bounce"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                    ) : (
                        <svg
                            className="w-8 h-8 text-danger animate-shake"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    )}
                    <p className="text-lg font-body text-gray-800 dark:text-gray-100">
                        {isCorrect ? "That's right!" : "Not quite. Let's review."}
                    </p>
                </div>

                {explanation && (
                    <div className="mb-4">
                        <p className="text-base font-body text-gray-700 dark:text-gray-300">
                            {explanation}
                        </p>
                    </div>
                )}

                {bonusMessage && (
                    <div className="mb-4">
                        <p className="text-sm font-body text-secondary">
                            Bonus: {bonusMessage}
                        </p>
                    </div>
                )}

                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="px-6 py-2 bg-primary hover:bg-primary-600 text-white font-body rounded-full transition transform duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-primary"
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>
    );
};

const NavigationControls = ({
    onPrevious,
    onNext,
    onRestart,
    disablePrevious,
    disableNext,
}) => {
    return (
        <div className="flex justify-center space-x-4 mt-6">
            <button
                onClick={onPrevious}
                disabled={disablePrevious}
                className="px-6 py-2 bg-secondary text-white font-body rounded-full transition transform duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-secondary disabled:opacity-50 disabled:cursor-not-allowed"
            >
                Previous
            </button>
            <button
                onClick={onNext}
                disabled={disableNext}
                className="px-6 py-2 bg-primary text-white font-body rounded-full transition transform duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-primary disabled:opacity-50 disabled:cursor-not-allowed"
            >
                Next
            </button>
            <button
                onClick={onRestart}
                className="px-6 py-2 bg-quaternary text-white font-body rounded-full transition transform duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-quaternary"
            >
                Restart
            </button>
        </div>
    );
};

const LeaderboardSocial = ({ leaderboardData, currentUserScore }) => {
    // Sort the leaderboard data in descending order of score
    const [sortedData, setSortedData] = useState([]);

    useEffect(() => {
        const sorted = [...leaderboardData].sort((a, b) => b.score - a.score);
        setSortedData(sorted);
    }, [leaderboardData]);

    // Function to handle social sharing based on platform
    const handleShare = (platform) => {
        const message = `I just scored ${currentUserScore} in Discipleship Trivia! Can you beat my score?`;
        if (platform === "twitter") {
            window.open(
                `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`,
                "_blank"
            );
        } else if (platform === "facebook") {
            window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    "https://your-trivia-game-link.com"
                )}&quote=${encodeURIComponent(message)}`,
                "_blank"
            );
        } else if (platform === "linkedin") {
            window.open(
                `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                    "https://your-trivia-game-link.com"
                )}`,
                "_blank"
            );
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-6">
            <h2 className="text-3xl font-heading text-primary mb-4 text-center">
                Leaderboard
            </h2>
            <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4 overflow-x-auto">
                <table className="w-full table-auto">
                    <thead>
                        <tr className="border-b border-gray-300 dark:border-gray-700">
                            <th className="py-2 text-left text-lg font-heading text-gray-800 dark:text-gray-100">
                                Rank
                            </th>
                            <th className="py-2 text-left text-lg font-heading text-gray-800 dark:text-gray-100">
                                Player
                            </th>
                            <th className="py-2 text-right text-lg font-heading text-gray-800 dark:text-gray-100">
                                Score
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map((player, index) => (
                            <tr
                                key={player.id}
                                className={`transition transform hover:scale-105 ${player.isCurrentUser ? "bg-quaternary/20" : ""
                                    }`}
                            >
                                <td className="py-2 text-gray-600 dark:text-gray-300">
                                    {index + 1}
                                </td>
                                <td className="py-2 flex items-center space-x-2">
                                    {player.avatar && (
                                        <img
                                            src={player.avatar}
                                            alt={player.name}
                                            className="h-8 w-8 rounded-full object-cover"
                                        />
                                    )}
                                    <span className="text-gray-800 dark:text-gray-100">
                                        {player.name}
                                    </span>
                                </td>
                                <td className="py-2 text-right text-gray-800 dark:text-gray-100">
                                    {player.score}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Social Sharing Section */}
            <div className="mt-8 text-center">
                <h3 className="text-2xl font-heading text-secondary mb-4">
                    Share Your Score!
                </h3>
                <div className="flex justify-center space-x-6">
                    <button
                        onClick={() => handleShare("twitter")}
                        className="flex items-center space-x-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full transition transform duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    >
                        <svg
                            className="w-5 h-5 fill-current"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.897-.957-2.178-1.555-3.594-1.555-2.723 0-4.932 2.21-4.932 4.932 0 .39.045.765.127 1.124-4.094-.205-7.725-2.165-10.159-5.144-.424.722-.666 1.562-.666 2.475 0 1.706.869 3.213 2.188 4.096-.807-.026-1.566-.247-2.228-.616v.062c0 2.385 1.693 4.374 3.946 4.827-.413.112-.849.171-1.296.171-.317 0-.626-.03-.927-.086.627 1.956 2.444 3.379 4.6 3.421-1.68 1.319-3.808 2.105-6.115 2.105-.398 0-.79-.023-1.175-.069 2.179 1.396 4.768 2.212 7.557 2.212 9.054 0 14.001-7.496 14.001-13.986 0-.21 0-.423-.016-.635.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
                        </svg>
                        <span>Twitter</span>
                    </button>
                    <button
                        onClick={() => handleShare("facebook")}
                        className="flex items-center space-x-2 bg-blue-700 hover:bg-blue-800 text-white px-4 py-2 rounded-full transition transform duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <svg
                            className="w-5 h-5 fill-current"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M22.675 0h-21.35C.597 0 0 .597 0 1.333v21.333C0 23.403.597 24 1.325 24h11.495v-9.294H9.691v-3.622h3.129V8.413c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.098 2.793.142v3.24l-1.917.001c-1.504 0-1.795.715-1.795 1.763v2.31h3.587l-.467 3.622h-3.12V24h6.116c.729 0 1.325-.597 1.325-1.334V1.333C24 .597 23.403 0 22.675 0z" />
                        </svg>
                        <span>Facebook</span>
                    </button>
                    <button
                        onClick={() => handleShare("linkedin")}
                        className="flex items-center space-x-2 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full transition transform duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <svg
                            className="w-5 h-5 fill-current"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M20.447 20.452H17.21v-5.569c0-1.328-.026-3.038-1.852-3.038-1.853 0-2.136 1.445-2.136 2.939v5.668H10.049V9h3.105v1.561h.045c.433-.821 1.492-1.68 3.073-1.68 3.289 0 3.894 2.164 3.894 4.984v6.587zM5.337 7.433a1.805 1.805 0 11-.001-3.61 1.805 1.805 0 01.001 3.61zM6.858 20.452H3.814V9h3.044v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.226.792 24 1.771 24h20.451C23.204 24 24 23.226 24 22.271V1.729C24 .774 23.204 0 22.225 0z" />
                        </svg>
                        <span>LinkedIn</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

const SettingsHelpModal = ({
    isOpen,
    onClose,
    onToggleDarkMode,
    isDarkMode,
    onToggleSound,
    isSoundEnabled,
    onToggleAnimations,
    isAnimationsEnabled,
}) => {
    const [activeTab, setActiveTab] = useState("settings");

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            {/* Background overlay */}
            <div
                className="absolute inset-0 bg-black opacity-50"
                onClick={onClose}
            ></div>

            {/* Modal content */}
            <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-2xl max-w-2xl w-full mx-4 p-6 animate-fadeIn">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-heading text-primary">Settings & Help</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 text-3xl leading-none"
                    >
                        &times;
                    </button>
                </div>

                {/* Tab Navigation */}
                <div className="flex space-x-4 mb-6">
                    <button
                        onClick={() => setActiveTab("settings")}
                        className={`px-4 py-2 rounded-t-lg transition-colors duration-300 ${activeTab === "settings"
                            ? "bg-primary text-white"
                            : "bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-300"
                            }`}
                    >
                        Game Settings
                    </button>
                    <button
                        onClick={() => setActiveTab("help")}
                        className={`px-4 py-2 rounded-t-lg transition-colors duration-300 ${activeTab === "help"
                            ? "bg-primary text-white"
                            : "bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-300"
                            }`}
                    >
                        Help & Instructions
                    </button>
                </div>

                {/* Tab Content */}
                {activeTab === "settings" && (
                    <div className="space-y-4">
                        <div className="flex items-center justify-between">
                            <span className="font-body text-gray-800 dark:text-gray-100">
                                Dark Mode
                            </span>
                            <button
                                onClick={onToggleDarkMode}
                                className={`w-12 h-6 flex items-center rounded-full p-1 transition-colors duration-300 ${isDarkMode ? "bg-primary" : "bg-gray-300"
                                    }`}
                            >
                                <div
                                    className={`w-4 h-4 bg-white rounded-full shadow-md transform transition-transform duration-300 ${isDarkMode ? "translate-x-6" : "translate-x-0"
                                        }`}
                                ></div>
                            </button>
                        </div>
                        <div className="flex items-center justify-between">
                            <span className="font-body text-gray-800 dark:text-gray-100">
                                Sound Effects
                            </span>
                            <button
                                onClick={onToggleSound}
                                className={`w-12 h-6 flex items-center rounded-full p-1 transition-colors duration-300 ${isSoundEnabled ? "bg-success" : "bg-gray-300"
                                    }`}
                            >
                                <div
                                    className={`w-4 h-4 bg-white rounded-full shadow-md transform transition-transform duration-300 ${isSoundEnabled ? "translate-x-6" : "translate-x-0"
                                        }`}
                                ></div>
                            </button>
                        </div>
                        <div className="flex items-center justify-between">
                            <span className="font-body text-gray-800 dark:text-gray-100">
                                Animations
                            </span>
                            <button
                                onClick={onToggleAnimations}
                                className={`w-12 h-6 flex items-center rounded-full p-1 transition-colors duration-300 ${isAnimationsEnabled ? "bg-quaternary" : "bg-gray-300"
                                    }`}
                            >
                                <div
                                    className={`w-4 h-4 bg-white rounded-full shadow-md transform transition-transform duration-300 ${isAnimationsEnabled ? "translate-x-6" : "translate-x-0"
                                        }`}
                                ></div>
                            </button>
                        </div>
                    </div>
                )}

                {activeTab === "help" && (
                    <div className="space-y-4 max-h-64 overflow-y-auto pr-2">
                        <h3 className="text-xl font-heading text-secondary">How to Play</h3>
                        <p className="font-body text-gray-800 dark:text-gray-100">
                            Welcome to Discipleship Trivia! Answer each question within the allotted time. Use the navigation buttons to move between questions, and your score and progress are tracked in real time.
                        </p>
                        <h3 className="text-xl font-heading text-secondary mt-4">
                            Game Rules
                        </h3>
                        <ul className="list-disc pl-5 font-body text-gray-800 dark:text-gray-100 space-y-1">
                            <li>You have limited time to answer each question.</li>
                            <li>Correct answers boost your score and unlock bonus insights.</li>
                            <li>Incorrect answers trigger feedback to help you learn and grow.</li>
                        </ul>
                        <h3 className="text-xl font-heading text-secondary mt-4">
                            Additional Information
                        </h3>
                        <p className="font-body text-gray-800 dark:text-gray-100">
                            For any issues or feedback, please contact our support team at support@discipleshiptrivia.com.
                        </p>
                    </div>
                )}

                <div className="flex justify-end mt-6">
                    <button
                        onClick={onClose}
                        className="px-6 py-2 bg-primary hover:bg-primary-600 text-white font-body rounded-full transition transform duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-primary"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

// Confetti component: Generates floating confetti pieces with randomized properties.
// Note: Ensure that the "fall" keyframes are defined in your Tailwind config or global CSS.
const Confetti = () => {
    return (
        <div className="absolute inset-0 pointer-events-none overflow-hidden">
            {Array.from({ length: 30 }).map((_, index) => (
                <div
                    key={index}
                    className="absolute bg-secondary opacity-75 rounded-full"
                    style={{
                        width: `${Math.random() * 10 + 5}px`,
                        height: `${Math.random() * 10 + 5}px`,
                        top: `${Math.random() * 100}%`,
                        left: `${Math.random() * 100}%`,
                        animation: `fall ${Math.random() * 3 + 2}s linear infinite`,
                        animationDelay: `${Math.random() * 2}s`
                    }}
                ></div>
            ))}
        </div>
    );
};

const EndScreen = ({ score, totalQuestions, onRestart, onViewLeaderboard }) => {
    const percentage = (score / totalQuestions) * 100;

    return (
        <div className="relative min-h-screen flex items-center justify-center bg-gradient-to-r from-primary-100 to-quaternary-100 dark:from-gray-800 dark:to-gray-700 overflow-hidden">
            <Confetti />
            <div className="relative z-10 bg-white dark:bg-gray-900 rounded-xl shadow-2xl p-8 max-w-lg mx-4 text-center transform transition-all duration-700 animate-fadeIn scale-95 hover:scale-100">
                <h2 className="text-4xl md:text-5xl font-heading text-primary mb-4">
                    Congratulations!
                </h2>
                <p className="text-xl font-body text-gray-700 dark:text-gray-300 mb-6">
                    You scored <span className="font-bold text-secondary">{score}</span> out of{" "}
                    <span className="font-bold text-secondary">{totalQuestions}</span>!
                </p>
                <div className="mb-6">
                    <div className="w-full bg-gray-300 dark:bg-gray-600 rounded-full h-6 overflow-hidden">
                        <div
                            className="bg-tertiary h-full transition-all duration-500"
                            style={{ width: `${percentage}%` }}
                        ></div>
                    </div>
                    <p className="text-sm font-body text-gray-600 dark:text-gray-400 mt-2">
                        {percentage.toFixed(0)}% Completed
                    </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                    <button
                        onClick={onRestart}
                        className="px-6 py-3 bg-primary hover:bg-primary-600 text-white font-body rounded-full transition transform duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-primary"
                    >
                        Play Again
                    </button>
                    <button
                        onClick={onViewLeaderboard}
                        className="px-6 py-3 bg-secondary hover:bg-secondary-600 text-white font-body rounded-full transition transform duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-secondary"
                    >
                        View Leaderboard
                    </button>
                </div>
                <div className="mt-6">
                    <button
                        onClick={() => {
                            const message = `I scored ${score} out of ${totalQuestions} in Discipleship Trivia! Can you beat me?`;
                            window.open(
                                `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`,
                                "_blank"
                            );
                        }}
                        className="px-4 py-2 bg-quaternary hover:bg-quaternary-600 text-white font-body rounded-full transition transform duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-quaternary"
                    >
                        Share Your Score
                    </button>
                </div>
            </div>
        </div>
    );
};

const Footer = () => {
    return (
        <footer className="bg-gray-900 text-gray-200">
            <div className="max-w-7xl mx-auto px-4 py-8">
                <div className="flex flex-col md:flex-row justify-between">
                    {/* Branding Section */}
                    <div className="mb-6 md:mb-0">
                        <h3 className="text-2xl font-heading text-primary">
                            Discipleship Trivia
                        </h3>
                        <p className="mt-2 font-body text-gray-400">
                            Bringing faith, fellowship, and fun together.
                        </p>
                    </div>
                    {/* Quick Links & Resources */}
                    <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-12">
                        <div>
                            <h4 className="font-heading text-lg text-secondary mb-2">
                                Quick Links
                            </h4>
                            <ul className="space-y-1 font-body">
                                <li>
                                    <a
                                        href="/"
                                        className="hover:text-primary transition-colors"
                                    >
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/leaderboard"
                                        className="hover:text-primary transition-colors"
                                    >
                                        Leaderboard
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/settings"
                                        className="hover:text-primary transition-colors"
                                    >
                                        Settings
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/help"
                                        className="hover:text-primary transition-colors"
                                    >
                                        Help
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4 className="font-heading text-lg text-secondary mb-2">
                                Resources
                            </h4>
                            <ul className="space-y-1 font-body">
                                <li>
                                    <a
                                        href="https://www.biblegateway.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="hover:text-primary transition-colors"
                                    >
                                        Bible Gateway
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.churchofjesuschrist.org/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="hover:text-primary transition-colors"
                                    >
                                        Church Info
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.youversion.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="hover:text-primary transition-colors"
                                    >
                                        YouVersion Bible
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.desiringgod.org/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="hover:text-primary transition-colors"
                                    >
                                        Desiring God
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Divider & Social Section */}
                <div className="mt-8 border-t border-gray-700 pt-4 flex flex-col md:flex-row justify-between items-center">
                    <p className="font-body text-sm text-gray-500">
                        &copy; {new Date().getFullYear()} Discipleship Trivia. All rights
                        reserved.
                    </p>
                    <div className="flex space-x-4 mt-4 md:mt-0">
                        <a
                            href="https://twitter.com"
                            target="_blank"
                            rel="noreferrer"
                            className="text-gray-400 hover:text-primary transition-colors"
                        >
                            <svg
                                className="w-6 h-6"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.897-.957-2.178-1.555-3.594-1.555-2.723 0-4.932 2.21-4.932 4.932 0 .39.045.765.127 1.124-4.094-.205-7.725-2.165-10.159-5.144-.424.722-.666 1.562-.666 2.475 0 1.706.869 3.213 2.188 4.096-.807-.026-1.566-.247-2.228-.616v.062c0 2.385 1.693 4.374 3.946 4.827-.413.112-.849.171-1.296.171-.317 0-.626-.03-.927-.086.627 1.956 2.444 3.379 4.6 3.421-1.68 1.319-3.808 2.105-6.115 2.105-.398 0-.79-.023-1.175-.069 2.179 1.396 4.768 2.212 7.557 2.212 9.054 0 14.001-7.496 14.001-13.986 0-.21 0-.423-.016-.635.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
                            </svg>
                        </a>
                        <a
                            href="https://facebook.com"
                            target="_blank"
                            rel="noreferrer"
                            className="text-gray-400 hover:text-primary transition-colors"
                        >
                            <svg
                                className="w-6 h-6"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M22.675 0h-21.35C.597 0 0 .597 0 1.333v21.333C0 23.403.597 24 1.325 24h11.495v-9.294H9.691v-3.622h3.129V8.413c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.098 2.793.142v3.24l-1.917.001c-1.504 0-1.795.715-1.795 1.763v2.31h3.587l-.467 3.622h-3.12V24h6.116c.729 0 1.325-.597 1.325-1.334V1.333C24 .597 23.403 0 22.675 0z" />
                            </svg>
                        </a>
                        <a
                            href="https://linkedin.com"
                            target="_blank"
                            rel="noreferrer"
                            className="text-gray-400 hover:text-primary transition-colors"
                        >
                            <svg
                                className="w-6 h-6"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M19 0h-14c-2.76 0-5 2.24-5 5v14c0 2.76 2.24 5 5 5h14c2.76 0 5-2.24 5-5v-14c0-2.76-2.24-5-5-5zm-11 19h-3v-10h3v10zm-1.5-11.28c-.97 0-1.75-.78-1.75-1.74s.78-1.74 1.75-1.74 1.75.78 1.75 1.74-.78 1.74-1.75 1.74zm13.5 11.28h-3v-5.6c0-1.34-.03-3.07-1.87-3.07-1.87 0-2.16 1.46-2.16 2.97v5.7h-3v-10h2.89v1.36h.04c.4-.76 1.38-1.56 2.84-1.56 3.04 0 3.6 2 3.6 4.59v5.61z" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

const TriviaGame = () => {
    // Possible game states: "intro", "question", "feedback", "end"
    const [gameState, setGameState] = useState("intro");

    // Example state values; in a full implementation these would be dynamic.
    const currentScore = 10;
    const currentQuestion = 1;
    const totalQuestions = 10;
    const sampleQuestion = "Who was the first king from the tribe of Judah?";
    const sampleCategory = "Bible Stories";
    const sampleOptions = [
        { id: 1, text: "Saul" },
        { id: 2, text: "David" },
        { id: 3, text: "Solomon" },
        { id: 4, text: "Rehoboam" },
    ];
    const correctAnswerId = 2;

    // Handlers to change the game state
    const startGame = () => setGameState("question");
    const submitAnswer = (selectedId) => {
        // Process answer, update score, etc.
        // For demonstration, immediately show feedback:
        setGameState("feedback");
        console.log("Selected answer ID:", selectedId);
    };
    const closeFeedback = () => {
        // Decide whether to proceed to the next question or end the game.
        // Here we transition to "end" for demonstration.
        setGameState("end");
    };
    const restartGame = () => setGameState("intro");

    return (
        <div className="flex flex-col min-h-screen">
            {/* Header can be static */}
            <header className="bg-white dark:bg-gray-800 shadow">
                <div className="max-w-7xl mx-auto px-4 py-6 flex items-center justify-between">
                    <div className="flex items-center">
                        <img
                            src="/path/to/logo.png"
                            alt="Church Logo"
                            className="h-10 w-auto mr-3"
                        />
                        <h1 className="text-2xl font-heading text-primary">
                            Discipleship Trivia
                        </h1>
                    </div>
                    <nav className="flex space-x-6">
                        <a
                            href="/"
                            className="text-base font-body text-gray-600 hover:text-primary transition-colors duration-200"
                        >
                            Home
                        </a>
                        <a
                            href="/leaderboard"
                            className="text-base font-body text-gray-600 hover:text-primary transition-colors duration-200"
                        >
                            Leaderboard
                        </a>
                        <a
                            href="/help"
                            className="text-base font-body text-gray-600 hover:text-primary transition-colors duration-200"
                        >
                            Help
                        </a>
                        <a
                            href="/settings"
                            className="text-base font-body text-gray-600 hover:text-primary transition-colors duration-200"
                        >
                            Settings
                        </a>
                    </nav>
                </div>
            </header>

            <main className="flex-grow bg-primary-50 dark:bg-gray-900">
                <div className="max-w-3xl mx-auto py-8">
                    {gameState === "intro" && (
                        <IntroScreen onStart={startGame} />
                    )}

                    {gameState === "question" && (
                        <>
                            <QuestionDisplay
                                question={sampleQuestion}
                                category={sampleCategory}
                                timer={10}
                            />
                            <AnswerOptions
                                options={sampleOptions}
                                onAnswerSelect={submitAnswer}
                                selectedAnswer={null} // Update as needed
                                correctAnswerId={correctAnswerId}
                                disabled={false}
                            />
                            <Timer duration={10} onComplete={() => console.log("Time's up!")} />
                            <ScoreProgressTracker
                                currentScore={currentScore}
                                currentQuestion={currentQuestion}
                                totalQuestions={totalQuestions}
                            />
                            <NavigationControls
                                onPrevious={() => console.log("Go to previous question")}
                                onNext={() => console.log("Go to next question")}
                                onRestart={restartGame}
                                disablePrevious={true}
                                disableNext={false}
                            />
                        </>
                    )}

                    {gameState === "feedback" && (
                        <FeedbackModal
                            isOpen={true}
                            onClose={closeFeedback}
                            isCorrect={true} // Replace with actual logic
                            explanation="David was the first king from the tribe of Judah. He was anointed by Samuel and succeeded Saul."
                            bonusMessage="Great job! Keep up the good work."
                        />
                    )}

                    {gameState === "end" && (
                        <EndScreen
                            score={currentScore}
                            totalQuestions={totalQuestions}
                            onRestart={restartGame}
                            onViewLeaderboard={() => console.log("View the leaderboard")}
                        />
                    )}
                </div>

                {/* Optionally, conditionally render other components like Leaderboard or Settings modals */}
            </main>

            <LeaderboardSocial
                leaderboardData={[]}
                currentUserScore={currentScore}
            />

            <SettingsHelpModal
                isOpen={false}
                onClose={() => console.log("Close modal")}
                onToggleDarkMode={() => console.log("Toggle dark mode")}
                isDarkMode={false}
                onToggleSound={() => console.log("Toggle sound")}
                isSoundEnabled={true}
                onToggleAnimations={() => console.log("Toggle animations")}
                isAnimationsEnabled={true}
            />

            <footer className="bg-white dark:bg-gray-800 shadow">
                <Footer />
            </footer>
        </div>
    );
};

export default TriviaGame;
