// src/pages/Settings/tabs/DangerZoneTab.js
import React from "react";
import axios from "axios";

const DangerZoneTab = ({ user }) => {
    const handleCleanSlate = () => {
        const confirmed = window.confirm("Delete all data? This cannot be undone.");
        if (confirmed) {
            axios.post(`${process.env.REACT_APP_API_URL}/api/answers/clean-slate/${user.uid}`);
            alert("Clean slate executed. (Confirm backend integration)");
        }
    };

    const handleDeleteAccount = () => {
        const confirmed = window.confirm("Delete your account? This cannot be undone.");
        if (confirmed) {
            // Future: dispatch(deleteUserAccount(user.uid))
            console.log("Account deleted.");
            alert("Account deletion requested. (TODO: Backend integration)");
        }
    };

    return (
        <div>
            <h3 className="mb-4 text-2xl font-bold">Danger Zone (Coming Soon)</h3>

            {/* Reset All Data */}
            <div className="mb-6">
                <button className="p-2 mr-2 border rounded-lg bg-danger hover:bg-transparent hover:text-danger">
                    Reset All Data
                </button>
            </div>

            {/* Clean Slate */}
            <div className="mb-6">
                <button
                    onClick={handleCleanSlate}
                    className="p-2 mr-2 border rounded-lg bg-danger hover:bg-transparent hover:text-danger"
                >
                    Clean Slate
                </button>
                <span>This will delete all your answers and reset your progress.</span>
            </div>

            {/* Delete Account */}
            <div className="mb-6">
                <button
                    onClick={handleDeleteAccount}
                    className="p-2 border rounded-lg bg-danger hover:bg-transparent hover:text-danger"
                >
                    Delete Account
                </button>
            </div>
        </div>
    );
};

export default DangerZoneTab;
