// src/pages/Settings/SettingsPage.js
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import Navbar from "../../components/constants/Navbar";
import Footer from "../../components/constants/Footer";
import { tabs } from "./helpers/constants";

import ProfileTab from "./tabs/ProfileTab";
import PreferencesTab from "./tabs/PreferencesTab";
import PrivacyTab from "./tabs/PrivacyTab";
import SupportTab from "./tabs/SupportTab";
import DangerZoneTab from "./tabs/DangerZoneTab";

const SettingsPage = () => {
  const reduxUser = useSelector((state) => state.user.user);
  const uid = reduxUser?.uid;

  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState("profile");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      if (uid) {
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${uid}`);
          setUser(res.data);
        } catch (err) {
          console.error(err);
        }
      }
    };
    fetchUser();
  }, [uid]);

  useEffect(() => {
    if (message || error) {
      const timer = setTimeout(() => {
        setMessage("");
        setError("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message, error]);

  if (!user) return <div>Loading...</div>;

  const renderTab = () => {
    switch (activeTab) {
      case "profile":
        return <ProfileTab user={user} setUser={setUser} setMessage={setMessage} setError={setError} />;
      case "preferences":
        return <PreferencesTab user={user} setUser={setUser} setMessage={setMessage} />;
      case "privacy":
        return <PrivacyTab user={user} setUser={setUser} setMessage={setMessage} />;
      case "support":
        return <SupportTab />;
      case "danger-zone":
        return <DangerZoneTab user={user} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Navbar />
      <div className="mt-8 w-full flex flex-col md:flex-row gap-5 px-3 md:px-16 lg:px-28 text-[#161931]">
        {/* Tabs */}
        <aside className="hidden py-4 md:w-1/3 lg:w-1/4 md:block">
          <div className="sticky flex flex-col gap-2 p-4 text-sm border-r border-primary-100 top-12">
            <h2 className="pl-3 mb-4 text-2xl font-semibold">Settings</h2>
            {tabs.map((tab) => (
              <button
                key={tab.value}
                onClick={() => setActiveTab(tab.value)}
                className={`flex items-center px-3 py-2.5 font-semibold rounded-full ${
                  activeTab === tab.value ? "bg-primary-200 text-primary-900" : "hover:text-primary-900 hover:border"
                }`}
              >
                {tab.name}
              </button>
            ))}
          </div>
        </aside>

        {/* Tab Content */}
        <main className="w-full py-1 md:w-2/3 lg:w-3/4">
          <div className="p-4 bg-white shadow rounded-xl">
            {renderTab()}
            {message && <p className="mt-4 text-center text-green-500">{message}</p>}
            {error && <p className="mt-4 text-center text-red-500">{error}</p>}
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default SettingsPage;
