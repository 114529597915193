import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { motion, AnimatePresence } from "framer-motion";

// Extract words array outside the component to avoid recreation on every render
const cyclingWords = ["money", "strength", "power", "fame", "possessions", "status", "recognition", "prestige"];

const Hero = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % cyclingWords.length);
    }, 1750); // Change word every 2 seconds

    return () => clearInterval(interval);
  }, []); // No dependencies since cyclingWords is static

  return (
    <div className="min-h-screen max-w-[988px] mx-auto">
      <div className="relative mt-12 isolate font-heading">
        <div className="px-6 py-12 mx-auto max-w-7xl sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-12">
          <div className="max-w-2xl mx-auto lg:mx-0 lg:flex-auto">
            {/* Donate Banner */}
            <div className="flex mb-4">
              <div className="relative flex items-center px-4 py-1 text-gray-600 bg-white rounded-xl gap-x-4 text-sm leading-6 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                <span className="font-semibold text-primary-600">Donate</span>
                <span aria-hidden="true" className="w-px h-4 bg-gray-900/10" />
                <Link to="/about" className="flex items-center gap-x-1">
                  <span aria-hidden="true" className="absolute inset-0" />
                  Contribute to the mission
                  <ChevronRightIcon
                    aria-hidden="true"
                    className="w-5 h-5 -mr-2 text-gray-400"
                  />
                </Link>
              </div>
            </div>

            {/* Hero Text */}
            <div className="text-5xl font-semibold tracking-tight text-gray-900 text-pretty sm:text-6xl">
              <h1 className="">
                The Lord doesn't need{" "}
                <AnimatePresence mode="wait">
                  <motion.span
                    className="text-gray-500 cycling-word"
                    key={currentWordIndex}
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    transition={{ duration: 0.5 }}
                  >
                    {cyclingWords[currentWordIndex]}
                  </motion.span>
                </AnimatePresence>
                .
              </h1>
              <h2 className="">
                He needs{" "}
                <span className="text-primary relative inline-block stroke-current">
                  you
                </span>
                .
              </h2>
            </div>

            {/* Hero Description */}
            <div className="flex items-center mt-10 gap-x-6">
              <Link
                to="/self-assessment"
                className="rounded-2xl bg-primary px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                Get started
              </Link>
              <Link
                to="/about"
                className="font-semibold text-gray-900 text-sm"
              >
                Learn more <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>

          {/* Hero Image */}
          <div className="mt-16 sm:mt-24 lg:mt-0 max-w-lg mx-auto">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2Flion-lamb.svg?alt=media&token=d7ed9f51-dbae-4709-92ea-3a67bc970c8a"
              alt="Disciplesheep and Lion"
              className="max-w-full mx-auto lg:w-full drop-shadow-xl"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
