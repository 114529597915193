import {
  FireIcon,
  ShieldCheckIcon,
  HeartIcon,
  SparklesIcon,
  ClockIcon,
  FaceSmileIcon,
  SunIcon,
  ScaleIcon,
  GiftIcon,
  GlobeAltIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";

export const getSectionColor = (section) => {
  const colors = {
    Faithful: "#95CBE4",
    Pure: "#DCE5EF",
    Loving: "#EF837B",
    Hopeful: "#C8EA6C",
    Patient: "#38b2ac",
    Meek: "#C1B1A8",
    True: "#EBD224",
    Wise: "#FFE55D",
    Grateful: "#F3A616",
    Joyous: "#FFE55D",
    Merciful: "#2F9393",
    Peaceful: "#95CBE4",
  };

  return colors[section] || "#ffffff";
};

export const getModIcon = (mod) => {
  const icons = {
    Faithful: SparklesIcon,
    Pure: ShieldCheckIcon,
    Loving: HeartIcon,
    Hopeful: FireIcon,
    Patient: ClockIcon,
    Meek: WrenchIcon,
    True: SunIcon,
    Wise: ScaleIcon,
    Grateful: GiftIcon,
    Joyous: FaceSmileIcon,
    Merciful: HeartIcon,
    Peaceful: GlobeAltIcon,
  };

  return icons[mod] || null;
};
