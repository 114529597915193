// src/store/reducers/userSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUserProfileData,
  fetchUserAssessmentData,
  updateUserProfile,
  updateUserProfilePhoto,
} from "../actions/userActions";

const initialState = {
  user: null,
  profileData: null,
  assessmentData: null,
  language: "en-us",
  loading: false,
  error: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // For authentication, set user data
    login: (state, action) => {
      state.user = action.payload;
    },
    // Clear state on logout
    logout: (state) => {
      state.user = null;
      state.profileData = null;
      state.assessmentData = null;
    },
    // General update of user information
    updateUser: (state, action) => {
      // Merge all provided user properties
      state.user = { ...state.user, ...action.payload };
    },
    // Update only the language
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    // Optional: update photo URL directly (if needed for local updates)
    updatePhotoURL: (state, action) => {
      if (state.user) {
        state.user.photoURL = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle profile fetching
      .addCase(fetchUserProfileData.fulfilled, (state, action) => {
        state.profileData = action.payload;
      })
      // Handle assessment data fetching
      .addCase(fetchUserAssessmentData.fulfilled, (state, action) => {
        state.assessmentData = action.payload;
      })
      // Update user profile after a successful update
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload };
      })
      // Update the photo URL after successful image upload
      .addCase(updateUserProfilePhoto.fulfilled, (state, action) => {
        // Assume action.payload is an object with a photoURL property
        if (state.user) {
          state.user.photoURL = action.payload.photoURL;
        }
      });
  },
});

// Export actions
export const { login, logout, updateUser, setLanguage, updatePhotoURL } = userSlice.actions;

// Selectors for different pieces of state
export const selectUser = (state) => state.user.user;
export const selectLanguage = (state) => state.user.language;
export const selectProfileData = (state) => state.user.profileData;
export const selectAssessmentData = (state) => state.user.assessmentData;

export default userSlice.reducer;
