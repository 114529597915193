import React, { useState } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Navbar from "../components/constants/Navbar";
import { getSectionColor, getModIcon } from "../utils/getColor";
import { useSelector } from "react-redux";
import { selectUser } from "../store/reducers/userSlice";

const Dashboard = () => {
  const user = useSelector(selectUser);

  // Dummy data for key metrics and activities
  const streak = 7;
  const dailyGoalProgress = 75;
  const achievements = ["Prayer Champion", "Weekly Devotional Streak"];
  const totalPrayers = 20;
  const currentMoD = "Faithful";
  const ModIcon = getModIcon(currentMoD);
  const Icon = ModIcon || null;

  // Dummy data for goals
  const dailyGoals = [
    { id: 1, text: "Read scripture", completed: false },
    { id: 2, text: "Pray", completed: true },
  ];
  const monthlyGoals = [
    { id: 1, text: "Attend service", completed: false },
    { id: 2, text: "Volunteer", completed: false },
  ];
  const quarterlyGoals = [
    { id: 1, text: "Complete a study guide", completed: true },
    { id: 2, text: "Share your faith story", completed: false },
  ];

  // State for goals tab and checklist (for simplicity, checkboxes are not fully interactive)
  const [activeTab, setActiveTab] = useState("daily");

  // Dummy data for recent activity and notifications
  const recentActivity = [
    { id: 1, type: "Prayer", description: "You prayed 4 times today", time: "10:00 AM" },
    { id: 2, type: "Devotional", description: "Completed today's devotional", time: "9:00 AM" },
    { id: 3, type: "Goal", description: "Updated goal progress", time: "Yesterday" },
  ];
  const notifications = [
    { id: 1, message: "New devotional available now!" },
    { id: 2, message: "Your friend John just achieved a new streak!" },
  ];
  // Dummy data for friends' activity feed
  const friendsActivity = [
    { id: 1, message: "Michael just finished a study guide for hope, check it out!" },
    { id: 2, message: "Isadora is on a 54 day streak, look at her profile!" },
    { id: 3, message: "John updated his devotional thoughts." },
  ];

  // Quick Actions handlers
  const handleRecordPrayer = () => {
    alert("Record Prayer clicked");
  };
  const handleShareAchievements = () => {
    alert("Share Achievements clicked");
  };
  const handleUpdateGoals = () => {
    alert("Update Goals clicked");
  };

  return (
    <>
      <Navbar />
      <div className="container max-w-[988px] mx-auto px-4 py-6 space-y-6">

        {/* Header Section */}
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <div className="space-y-2">
            <h1 className="text-4xl font-bold">
              Welcome back, {user?.displayName || "User"}!
            </h1>
            <p className="text-gray-500">
              Today is {new Date().toLocaleDateString()}
            </p>
            <p className="italic text-gray-700">
              "I can do all this through Him who gives me strength." - Philippians 4:13
            </p>
          </div>
        </div>

        {/* Banner / Event Promotion */}
        <div className="inset-x-0 pointer-events-none sm:px-6 sm:pb-5 lg:px-8">
          <div
            style={{ backgroundColor: getSectionColor(currentMoD) }}
            className="pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-900 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5"
          >
            <p className="text-white text-sm">
              <a href="#">
                <strong className="font-semibold">GeneriCon 2023</strong>
                {Icon && (
                  <Icon className="w-6 h-6 text-white inline-block ml-2" aria-hidden="true" />
                )}
                <svg
                  viewBox="0 0 2 2"
                  aria-hidden="true"
                  className="mx-2 inline w-2 h-2 fill-current"
                >
                  <circle r={1} cx={1} cy={1} />
                </svg>
                Join us in Denver from June 7 – 9 to see what’s coming next&nbsp;
                <span aria-hidden="true">&rarr;</span>
              </a>
            </p>
            <button
              type="button"
              className="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]"
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon aria-hidden="true" className="text-white w-5 h-5" />
            </button>
          </div>
        </div>

        {/* Key Metrics Section */}
        <div className="grid grid-cols-1 gap-4 p-4 bg-gray-100 rounded-lg sm:grid-cols-4">
          <div className="text-center">
            <p className="text-lg font-semibold">🔥 Streak</p>
            <p className="text-2xl font-bold">{streak} Days</p>
          </div>
          <div className="text-center">
            <p className="text-lg font-semibold">🎯 Goal Progress</p>
            <div className="relative w-full h-2 bg-gray-300 rounded-full">
              <div
                className="absolute top-0 left-0 h-2 bg-blue-500 rounded-full"
                style={{ width: `${dailyGoalProgress}%` }}
              ></div>
            </div>
            <p className="mt-2 text-sm">{dailyGoalProgress}% Complete</p>
          </div>
          <div className="text-center">
            <p className="text-lg font-semibold">🏆 Achievements</p>
            <p className="text-2xl font-bold">{achievements.length}</p>
          </div>
          <div className="text-center">
            <p className="text-lg font-semibold">🙏 Total Prayers</p>
            <p className="text-2xl font-bold">{totalPrayers}</p>
          </div>
        </div>

        {/* Mark of Discipleship Card */}
        <div
          style={{ backgroundColor: getSectionColor(currentMoD) }}
          className="p-4 rounded-lg shadow text-white flex items-center justify-between"
        >
          <div className="flex items-center">
            {Icon && <Icon className="w-8 h-8 mr-3" aria-hidden="true" />}
            <div>
              <h2 className="text-2xl font-semibold">Enhance Your {currentMoD} Focus</h2>
              <p className="mt-1">
                Next step: Watch the latest General Conference talk or try a related devotional activity.
              </p>
            </div>
          </div>
          <button className="p-2">
            <XMarkIcon className="w-6 h-6" aria-hidden="true" />
          </button>
        </div>

        {/* Interactive Chart Placeholder */}
        <div className="p-4 bg-white rounded-lg shadow">
          <h2 className="mb-4 text-2xl font-semibold">Weekly Progress</h2>
          <div className="h-48 flex items-center justify-center text-gray-500">
            {/* Placeholder: Integrate a chart library (e.g., Chart.js, Recharts) later */}
            <span>Chart Placeholder</span>
          </div>
        </div>

        {/* Goals Card with Tabs */}
        <div className="p-4 bg-white rounded-lg shadow">
          <h2 className="mb-4 text-2xl font-semibold">Goals</h2>
          <div className="flex space-x-4 mb-4">
            <button
              onClick={() => setActiveTab("daily")}
              className={`px-4 py-2 ${activeTab === "daily" ? "border-b-2 border-blue-500" : ""}`}
            >
              Daily
            </button>
            <button
              onClick={() => setActiveTab("monthly")}
              className={`px-4 py-2 ${activeTab === "monthly" ? "border-b-2 border-blue-500" : ""}`}
            >
              Monthly
            </button>
            <button
              onClick={() => setActiveTab("quarterly")}
              className={`px-4 py-2 ${activeTab === "quarterly" ? "border-b-2 border-blue-500" : ""}`}
            >
              Quarterly
            </button>
          </div>
          <ul className="space-y-2">
            {(activeTab === "daily"
              ? dailyGoals
              : activeTab === "monthly"
              ? monthlyGoals
              : quarterlyGoals
            ).map((goal) => (
              <li key={goal.id} className="flex items-center space-x-2">
                <input type="checkbox" checked={goal.completed} onChange={() => {}} />
                <span className={goal.completed ? "line-through" : ""}>{goal.text}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Journal Entry Card */}
        <div className="w-full p-4 bg-white rounded-lg shadow">
          <h2 className="mb-4 text-2xl font-semibold">Quick Journal Entry</h2>
          <textarea
            placeholder="How did your day go?"
            className="w-full p-2 border border-gray-300 rounded-md"
            rows={3}
          ></textarea>
          <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md">
            Submit Entry
          </button>
        </div>

        {/* Friends' Activity Feed Card */}
        <div className="w-full p-4 bg-white rounded-lg shadow">
          <h2 className="mb-4 text-2xl font-semibold">Friends' Activity</h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            {friendsActivity.map((item) => (
              <div key={item.id} className="p-3 bg-gray-50 rounded-md shadow">
                {item.message}
              </div>
            ))}
          </div>
        </div>

        {/* Recent Activity Section */}
        <div>
          <h2 className="mb-4 text-2xl font-semibold">Recent Activity</h2>
          <div className="space-y-4">
            {recentActivity.map((activity) => (
              <div key={activity.id} className="p-4 bg-gray-100 rounded-lg shadow-sm">
                <div className="flex items-center justify-between">
                  <p className="font-semibold">{activity.type}</p>
                  <p className="text-sm text-gray-500">{activity.time}</p>
                </div>
                <p className="mt-1 text-gray-700">{activity.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Notifications Section */}
        <div className="p-4 bg-gray-50 rounded-lg">
          <h2 className="mb-4 text-2xl font-semibold">Notifications</h2>
          <ul className="space-y-2">
            {notifications.map((notification) => (
              <li key={notification.id} className="p-3 bg-white rounded-md shadow-sm">
                {notification.message}
              </li>
            ))}
          </ul>
        </div>

        {/* Achievement Progress Card */}
        <div className="p-4 bg-white rounded-lg shadow">
          <h2 className="mb-4 text-2xl font-semibold">Achievement Progress</h2>
          <div className="flex items-center space-x-4">
            <p className="text-lg font-semibold">Achievements Unlocked:</p>
            <p className="text-2xl font-bold">{achievements.length}</p>
          </div>
          <div className="mt-4">
            {/* Example progress bar; adjust logic as needed */}
            <div className="w-full h-2 bg-gray-300 rounded-full">
              <div
                className="h-2 bg-green-500 rounded-full"
                style={{ width: `${(achievements.length / 10) * 100}%` }}
              ></div>
            </div>
          </div>
        </div>

        {/* Quick Actions Section */}
        <div className="p-4 bg-white rounded-lg shadow">
          <h2 className="mb-4 text-2xl font-semibold">Quick Actions</h2>
          <div className="flex flex-col gap-4 sm:flex-row">
            <button
              onClick={handleRecordPrayer}
              className="flex-1 px-4 py-2 font-semibold text-white bg-green-500 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              Record Prayer
            </button>
            <button
              onClick={handleShareAchievements}
              className="flex-1 px-4 py-2 font-semibold text-white bg-purple-500 rounded-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500"
            >
              Share Achievements
            </button>
            <button
              onClick={handleUpdateGoals}
              className="flex-1 px-4 py-2 font-semibold text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Update Goals
            </button>
          </div>
        </div>

        {/* Daily Recommendations */}
        <div className="p-4 bg-gray-100 rounded-lg">
          <h2 className="mb-4 text-2xl font-semibold">Today's Recommendations</h2>
          <ul className="space-y-2">
            {[
              "Write a new prayer",
              "Complete today's devotional",
              "Reflect on yesterday's activity",
            ].map((recommendation, index) => (
              <li key={index} className="flex items-center space-x-2">
                <span className="text-blue-500">➤</span>
                <p>{recommendation}</p>
              </li>
            ))}
          </ul>
        </div>

        {/* Encouragement Section */}
        <div className="p-4 bg-gray-100 rounded-lg">
          <h2 className="mb-4 text-2xl font-semibold">Keep It Up!</h2>
          <p>
            You're on a {streak}-day streak! Share your progress with a friend or invite them to join you.
          </p>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
